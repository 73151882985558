import {
  Popover,
  PopoverContent,
  PopoverHandler,
} from "@material-tailwind/react";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TopNavigation from "../../../components/TopNavigation";
import Table from "../../../components/UX/Table/Table";
import { LoadingContext } from "../../../context/LoadingContext";
import {
  deleteAdminUserAPI,
  getAdminUsersAPI,
  getDefaultHandlerAPI,
  reactivateAdminAPI,
  updateDefaultHandlerAPI,
} from "../../../service/UserService";

export default function AdminManagement() {
  const history = useNavigate();
  const [adminUsers, setAdminUsers] = useState([]);
  const { setLoading } = useContext(LoadingContext);
  const handleDelete = async (id, isDeactivate = false, makeActive = false) => {
    if (makeActive) {
      const { statusCode, message } = await reactivateAdminAPI(id);
      if (statusCode === 200) {
        toast(message, { type: "success" });
        getAdminUsers();
      } else {
        toast(message, { type: "error" });
      }
    } else {
      const { statusCode, message } = await deleteAdminUserAPI(
        id,
        isDeactivate
      );
      if (statusCode === 200) {
        toast(message, { type: "success" });
        getAdminUsers();
      } else {
        toast(message, { type: "error" });
      }
    }
  };
  const handleDefaultHandler = async (admins, name) => {
    var name_ =
      name === admins.filter((admin) => admin.isHandler)[0].name ? null : name;
    setLoading(true);
    const { statusCode, message } = await updateDefaultHandlerAPI(name_);
    if (statusCode === 200) {
      toast(message, { type: "success" });
      getAdminUsers();
    } else {
      toast(message, { type: "error" });
    }
    setLoading(false);
  };
  const [columns, setColumns] = useState([
    {
      Header: "Id",
      accessor: "uid",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Is Default Assignee",
      accessor: "isHandler",
      Cell: (row) => (
        <input
          type="checkbox"
          checked={row.data[row.row.id].isHandler}
          onChange={() =>
            handleDefaultHandler(row.data, row.data[row.row.id].name)
          }
        />
      ),
    },
    {
      Header: "Roles",
      accessor: "roles",
      Cell: ({ value }) => {
        return (
          <Popover placement="bottom">
            <PopoverHandler>
              <button className=" text-gray-300">Click to Show</button>
            </PopoverHandler>
            <PopoverContent>
              <div className="p-4">
                {value.map((role) => (
                  <div className="text-black">
                    {role.roleName.replaceAll("_", " ").replaceAll("ROLE", "")}
                  </div>
                ))}
              </div>
            </PopoverContent>
          </Popover>
        );
      },
    },
    {
      Header: "Created At",
      accessor: "createdAt",
      Cell: ({ value }) => (
        <span className="text-gray-300 text-sm">
          {new Date(value).toLocaleString()}
        </span>
      ),
    },
    {
      Header: "Actions",
      accessor: "requestGenerated",
      Cell: (row) => (
        <div className="flex flex-row">
          <span
            onClick={() => {
              let data = row.data[row.row.index];
              history("/admin-management/add-new-admin", {
                state: {
                  ...data,
                  isEdit: true,
                },
              });
            }}
            className="bg-[#0E863E] cursor-pointer rounded-[10px]  px-2 py-1 text-sm text-center  h-[30px] text-white"
          >
            Edit
          </span>
          {row.data[row.row.index].isDeactivated ? (
            <span
              onClick={() => {
                let data = row.data[row.row.index];
                handleDelete(data.uid, true, true);
              }}
              className="bg-yellow-400 cursor-pointer ml-1 rounded-[10px] text-sm py-1  px-2 text-center  h-[30px] text-gray-700"
            >
              Activate
            </span>
          ) : (
            <span
              onClick={() => {
                let data = row.data[row.row.index];
                handleDelete(data.uid, false, false);
              }}
              className="bg-red-400 cursor-pointer ml-1 rounded-[10px] text-sm py-1  px-2 text-center  h-[30px] text-white"
            >
              Deactivate
            </span>
          )}
        </div>
      ),
    },
  ]);

  const getAdminUsers = async () => {
    setLoading(true);
    const { data } = await getAdminUsersAPI();
  
    const handlerResponse = await getDefaultHandlerAPI();
    const name = handlerResponse?.data?.data?.adminUser?.name || null;
  
    var data_ = data.users;

    data_.forEach((element) => {
      if (element.name === name) {
        element.isHandler = true;
      } else {
        element.isHandler = false;
      }
    });
    setAdminUsers(data_);
    setLoading(false);
  };
  

  // const getAdminUsers = async () => {
  //   setLoading(false);
  //   const { data } = await getAdminUsersAPI();
  //   const {
  //     data: {
  //       users: {
  //         adminUser: { name },
  //       },
  //     },
  //   } = await getDefaultHandlerAPI();
  //   var data_ = data.users;
  //   data_.forEach((element) => {
  //     if (element.name === name) {
  //       element.isHandler = true;
  //     } else {
  //       element.isHandler = false;
  //     }
  //   });
  //   setAdminUsers(data_);
  //   setLoading(false);
  // };
  useEffect(() => {
    getAdminUsers();
  }, []);
  return (
    <>
      <TopNavigation title="Admin Management" isBackNav={true} />
      <div className="px-4 mt-5 flex justify-between w-[100%]">
        <button onClick={() => history("/admin-management/add-new-admin")}>
          <span className="text-white bg-[#FFBF44] rounded-[10px] px-2 py-1 text-sm">
            Add New Admin
          </span>
        </button>
        <button onClick={() => history("/admin/handler")}>
          <span className="text-white bg-[#FFBF44] rounded-[10px] px-2 py-1 text-sm">
            Manage Users per Admin
          </span>
        </button>
      </div>
      <div className="h-auto  w-[100%] ">
        <Table
          size={adminUsers.length}
          currentPage={1}
          pageLimit={100}
          data={adminUsers}
          columns={columns}
          totalPages={1}
          showPagination={false}
        />
      </div>
    </>
  );
}
