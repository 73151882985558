import React, { useState } from "react";
import { useContext } from "react";
import TopNavigation from "../components/TopNavigation";
import { UserContext } from "../context/UserContext";
import { authenticateAPI, getOtpAPI, loginAPI } from "../service/AuthService";
import { toast } from "react-toastify";
import { encrypt, PUBLIC_KEY } from "../utils/constants";
import { JSEncrypt } from "jsencrypt";
export default function Login({ history }) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const { isLoggedIn, setUser, user } = useContext(UserContext);
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(PUBLIC_KEY);
  const handleSubmit = async () => {
    try {
      const { data, statusCode, response, message } = await loginAPI(
        userName,
        encrypt.encrypt(password)
      );

      if (statusCode == 200) {
        setUser({ User: data.data, isLoggedIn: true, token: data.jwt });
      }
      if (statusCode != 200) {
        toast(message, {
          type: "error",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="component_body h-[100vh]  justify-center">
      <img
        className="w-[45%] mb-5 sm:w-[25%] mt-2"
        src={require("../assets/img/wing_pay_logo.png")}
      />
      <div className="w-[95%] mt-10 sm:w-[80%]  text-[#7C7C7C] bg-[#1D2023] px-5 py-5 sm:p-8 rounded-[5px] flex flex-col items-start">
        <label
          className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2"
          htmlFor="grid-first-name"
        >
          Username
        </label>
        <input
          className="w-[100%] bg-transparent border-b-[1px] border-b-gray-600 outline-none mb-5 mt-2"
          onChange={(e) => setUserName(e.target.value)}
        />
        <label
          className="block uppercase tracking-wide text-gray-500 text-xs font-bold mb-2"
          htmlFor="grid-first-name"
        >
          Password
        </label>
        <div className="styled_input custom_input">
          <input
            onChange={(e) => setPassword(e.target.value)}
            type={isPasswordVisible ? "text" : "password"}
          />
          {isPasswordVisible ? (
            <svg
              onClick={() => setIsPasswordVisible(false)}
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          ) : (
            <svg
              onClick={() => setIsPasswordVisible(true)}
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
              />
            </svg>
          )}
        </div>
      </div>

      <button
        onClick={handleSubmit}
        className="w-[50%] mt-4 py-2 rounded-md bg-[#FFBF44] text-black font-bold flex justify-center items-center"
      >
        Login
      </button>
    </div>
  );
}
