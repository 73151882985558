import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TopNavigation from "../../components/TopNavigation";
import { appRoutes } from "../../routes/appRoutes";
import { todaysSummaryAPI } from "../../service/UserService";

export default function HomePage() {
  const navigate = useNavigate();
  const [navigations, setNavigations] = useState([
    {
      name: "Dashboard",
      link: "/dashboard",
      icon: require("../../assets/img/dashboard_icon.png"),
    },
    {
      name: "My Users",
      link: "/my-users",
      icon: require("../../assets/img/dashboard_icon_1.png"),
    },
    {
      name: "All Transactions",
      link: "/all-transactions",
      icon: require("../../assets/img/dashboard_icon_2.png"),
    },
    {
      name: "Admin Management",
      link: "/admin/admin-management",
      icon: require("../../assets/img/dashboard_icon_2.png"),
    },
    {
      name: "Leads",
      link: "/leads",
      icon: require("../../assets/img/dashboard_icon_3.png"),
    },
    {
      name: "Sitewise Report",
      link: "/sitewise-report",
      icon: require("../../assets/img/dashboard_icon_4.png"),
    },

    {
      name: "My Earnings",
      link: "/my-earnings",
      icon: require("../../assets/img/dashboard_icon_9.png"),
    },
    {
      name: "Payout Screenshots",
      link: "/payout-screenshots",
      icon: require("../../assets/img/dashboard_icon_6.png"),
    },
    {
      name: "Promotional Banners",
      link: "/promotional-banners",
      icon: require("../../assets/img/dashboard_icon_7.png"),
    },
    {
      name: "Refer Code",
      link: "/refer-code",
      icon: require("../../assets/img/dashboard_icon_8.png"),
    },
  ]);
  const [todaysSummary, setTodaysSummary] = useState({
    withdraw: 0,
    deposit: 0,
    newClients: 0,
    pnl: 0,
  });
  const getTodaysSummary = async () => {
    const { data, statusCode, message } = await todaysSummaryAPI();
    if (statusCode === 200) {
      setTodaysSummary(data);
    } else {
      console.log(message);
    }
  };
  useEffect(() => {
    getTodaysSummary();
  }, []);
  return (
    <>
      <TopNavigation showNotification={true} showBankIcon={true} />
      <div className="w-[100%] container  flex flex-col justify-center items-start">
        <img
          className="h-[20vh] self-center mt-8 mb-5"
          src={require("../../assets/img/wing_pay_logo.png")}
        />
        <div className="w-[90%] self-center  flex flex-col items-center justify-center m-5 rounded-xl p-2 bg-[#1A1D20]">
          <label
            className="block uppercase tracking-wide text-[#FFBF44] text-sm font-bold"
            htmlFor="grid-first-name"
          >
            Today's Summary
          </label>
          {/*       <p className="text-gray-200 text-lg">Today's Summary</p> */}
          <div className="bg-[#2A2E33] w-[100%] text-white mt-2 rounded-xl p-2">
            <div className="w-[100%] justify-between flex flex-row">
              <p>Deposit</p>
              <p>{todaysSummary.deposit}</p>
            </div>
            <div className="w-[100%] justify-between flex flex-row">
              <p>Withdraw</p>
              <p>{todaysSummary.withdraw}</p>
            </div>
            <div className="w-[100%] justify-between flex flex-row">
              <p>P/L</p>
              <p>{todaysSummary.pnl}</p>
            </div>
            <div className="w-[100%] justify-between flex flex-row">
              <p>New Clients</p>
              <p>{todaysSummary.newClients}</p>
            </div>
          </div>
        </div>

        <div className="ml-2 rounded-lg self-center overflow-y-auto bg-[#1A1D20] w-[95%]   grid grid-cols-3 p-3 gap-3 sm:grid-cols-2 md:grid-cols-5 sm:gap-5">
          {appRoutes.map(
            (route, index) =>
              route.showOnHome &&
              route.hasRole && (
                <Link to={route.path} key={index}>
                  <div className="flex flex-col text-center h-[8rem]  items-center justify-center rounded-lg bg-[#2A2E33] px-2 py-4">
                    <img
                      className="h-[5vh] w-[5vh] object-contain"
                      src={route.icon}
                    />
                    <p className="block uppercase tracking-wide text-gray-300 text-sm font-bold mt-2">
                      {route.title}
                    </p>
                  </div>
                </Link>
              )
          )}
        </div>
      </div>
    </>
  );
}
