import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DrawerContext } from "../context/DrawerContext";
import { UserContext } from "../context/UserContext";
import { appRoutes } from "../routes/appRoutes";
import { logOut } from "../utils/constants";

export default function SideBar() {
  const drawerRef = useRef(null);
  const [isSideBarOpen, setIsSideBarOpen] = useContext(DrawerContext);
  const {
    user: { User },
  } = useContext(UserContext);
  const history = useNavigate();

  const [sideNavs, setSideNavs] = useState([
    {
      title: "Dashboard",
      route: "/dashboard",
      icon: require("../assets/img/helpdesk_icon.png"),
    },
    {
      title: "My Users",
      route: "/my-users",
      icon: require("../assets/img/helpdesk_icon.png"),
    },
    {
      title: "All Transactions",
      route: "/all-transactions",
      icon: require("../assets/img/helpdesk_icon.png"),
    },
    {
      title: "Leads",
      route: "/leads",
      icon: require("../assets/img/helpdesk_icon.png"),
    },
    {
      title: "Bank management",
      route: "/bank-details",
      icon: require("../assets/img/helpdesk_icon.png"),
    },
    {
      title: "Sitewise Report",
      route: "/sitewise-report",
      icon: require("../assets/img/helpdesk_icon.png"),
    },
    {
      title: "My Earnings",
      route: "/my-earnings",
      icon: require("../assets/img/helpdesk_icon.png"),
    },
    {
      title: "Payout Screenshots",
      route: "/payout-screenshots",
      icon: require("../assets/img/helpdesk_icon.png"),
    },
    {
      title: "Promotional Banners",
      route: "/promotional-banners",
      icon: require("../assets/img/helpdesk_icon.png"),
    },
    {
      title: "Refer Code",
      route: "/refer-code",
      icon: require("../assets/img/helpdesk_icon.png"),
    },
  ]);
  const handleOutsideClick = (e) => {
    if (drawerRef.current && !drawerRef.current.contains(e.target)) {
      setIsSideBarOpen(false);
    }
  };

  return (
    <div className="w-[100%] fixed z-10  justify-start">
      <div class="relative side_drawer " onClick={handleOutsideClick}>
        <div class="flex w-72 flex-col sm:flex-row sm:justify-around">
          <div ref={drawerRef} class="w-72 bg-[#373D41] h-screen">
            <div
              onClick={() => history("/home")}
              class=" flex bg-[#212528] flex-col items-center text-left justify-start p-5"
            >
              <img
                class="h-10 mt-5"
                src={require("../assets/img/vertical_logo.png")}
              />
            </div>
            <nav class="mt-5 px-3 ">
              {appRoutes.map(
                (route, index) =>
                  route.showInSideBar &&
                  route.hasRole && (
                    <Link
                      to={route.path}
                      key={index}
                      class="flex items-center text-white text-sm font-light px-4 py-2 my-2 rounded-lg hover:bg-[#1A1D20] hover:text-amber-500"
                    >
                      <img
                        class="h-5 w-5 mr-3"
                        src={
                          route.icon
                            ? route.icon
                            : require("../assets/img/helpdesk_icon.png")
                        }
                      />
                      <span class="font-normal">{route.title}</span>
                    </Link>
                  )
              )}
              <div
                class="hover:text-gray-200  flex items-center p-2 my-3    text-gray-600 dark:text-gray-400 rounded-lg "
                onClick={logOut}
              >
                <img
                  class="h-4 w-4"
                  src={require("../assets/img/log_out_icon.png")}
                />
                <span class="mx-5 text-sm font-normal">Log Out</span>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
