import React, { useContext, useEffect } from "react";
import { useState } from "react";
import TopNavigation from "../../components/TopNavigation";
import Table from "../../components/UX/Table/Table";
import { LoadingContext } from "../../context/LoadingContext";
import { UserContext } from "../../context/UserContext";
import { getTransactionsAPI } from "../../service/UserService";
export default function Transactions() {
  const [pageLimit, setPageLimit] = useState(20);
  const [transactions, setTransactions] = useState([]);
  const { user } = useContext(UserContext);
  const [filters, setFilters] = useState([
    {
      name: "All",
      event: null,
      selected: true,
    },
    {
      name: "Deposit",
      event: "DEPOSIT_TO_WALLET",
      selected: false,
    },
    {
      name: "Withdrawal",
      event: "WITHDRAW_FROM_WALLET",
      selected: false,
    },
    {
      name: "Deposit to ID",
      event: "DEPOSIT_TO_ID",
      selected: false,
    },
    {
      name: "Withdrawal from ID",
      event: "WITHDRAW_FROM_ID",
      selected: false,
    },
    {
      name: "Create ID",
      event: "CREATE_ID",
      selected: false,
    },
    {
      name: "Other",
      event: "OTHER",
      selected: false,
    },
  ]);
  const loadFilters = (roles) => {
    let isAdmin = false;
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].roleName === "ROLE_ADMIN") {
        isAdmin = true;
        break;
      }
    }
    if (isAdmin) {
      setFilters([
        {
          name: "All",
          event: null,
          selected: true,
        },
        {
          name: "Deposit",
          event: "DEPOSIT_TO_WALLET",
          selected: false,
        },
        {
          name: "Withdrawal",
          event: "WITHDRAW_FROM_WALLET",
          selected: false,
        },
        {
          name: "Deposit to ID",
          event: "DEPOSIT_TO_ID",
          selected: false,
        },
        {
          name: "Withdrawal from ID",
          event: "WITHDRAW_FROM_ID",
          selected: false,
        },
        {
          name: "Create ID",
          event: "CREATE_ID",
          selected: false,
        },
        {
          name: "Other",
          event: "OTHER",
          selected: false,
        },
      ]);
    } else {
      setFilters([]);
    }
  };
  const { setLoading } = useContext(LoadingContext);
  const [selectedPayment, setSelectedPayment] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actualCoins, setActualCoins] = useState(0);
  const [tableData, setTableData] = useState({
    size: 0,
  });
  const nextPage = () => {
    if (currentPage < tableData.totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const loadTransactions = async () => {
    setLoading(true);
    const {
      data: {
        data: { content, size, totalPages, numberOfElements },
      },
    } = await getTransactionsAPI(
      pageLimit,
      currentPage,
      filters
        .filter((filter) => filter.selected && filter.event)
        .map((filter) => filter.event)
    );
    setTableData({ size, totalPages, numberOfElements });

    let sortedTransactions = content.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setLoading(false);
    setTransactions(sortedTransactions);
  };
  useEffect(() => {
    loadTransactions();
    loadFilters(user.User.roles);
  }, [currentPage, pageLimit]);
  useEffect(() => {
    loadTransactions();
  }, [filters]);
  const handleFilterChange = (index) => {
    let newFilters = [...filters];
    newFilters[index].selected = !newFilters[index].selected;
    setFilters(newFilters);
  };
  const [columns, setColumns] = useState([
    {
      Header: "Transaction Id",
      accessor: "transactionId",
    },
    {
      Header: "User Id",
      accessor: "toUserId",
    },
    {
      Header: "Coins",
      accessor: "coins",
    },
    {
      Header: "Transaction Status",
      accessor: "transactionStatus",
    },
    {
      Header: "Transaction Type",
      accessor: "transactionType",
    },
    {
      Header: "Request Generated At",
      accessor: "requestGenerated",
      Cell: (row) => (
        <span className="text-gray-400 text-sm">
          {row.value ? new Date(row.value).toDateString() : "Not Validated"}
        </span>
      ),
    },
    {
      Header: "Request Validated At",
      accessor: "requestValidated",
      Cell: (row) => (
        <span className="text-gray-400 text-sm">
          {row.value ? new Date(row.value).toDateString() : "Not Validated"}
        </span>
      ),
    },
  ]);
  return (
    <>
      <TopNavigation isBackNav={true} title="All Transaction" />
      <div className="flex flex-col   w-[100%] text-white text-center px-1">
        <div className="flex flex-wrap my-2">
          {filters.map(({ name, event, selected }, index) => (
            <div
              onClick={() => handleFilterChange(index)}
              className={`${
                selected ? "bg-[#FFBF44]" : ""
              } cursor-pointer px-2 py-1 m-1 border-[1px] border-gray-500 rounded-md text-sm`}
            >
              {name}
            </div>
          ))}
        </div>
        <div className="h-auto  w-[100%] ">
          <Table
            size={tableData.size}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            data={transactions}
            columns={columns}
            totalPages={tableData.totalPages}
            nextPage={nextPage}
            prevPage={prevPage}
            showPagination={true}
          />
        </div>
      </div>
    </>
  );
}
