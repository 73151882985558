import axios from "axios";
import { REACT_APP_API_URL } from "../utils/config";

export const getTokenFromLocalStorage = () => {
  let data = localStorage.getItem("user");
  if (data) {
    const { token } = JSON.parse(data);
    return token;
  } else {
    return "";
  }
};

export const getTransactionsAPI = async (
  size,
  pageNumber,
  transactionTypes,
  userId
) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}admin/user/transactions?pageNumber=${pageNumber}&size=${size}`,
      {
        transactionTypes,
        userId,
      },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getPaymentModesAPI = async (mobile) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}admin/payment-details`,
      {
        params: {
          mobile,
        },

        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const uploadFileAPI = async (file) => {
  var formData = new FormData();
  formData.append("file", file);
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}acc/upload-doc`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const depositCoinsAPI = async (coins, paymentProofs) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}acc/deposit-coins`,
      {
        coins,
        paymentProofs,
      },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const approveRejectTransactionAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}admin/user-deposit-withdraw-coins`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getSignedUrlAPI = async (key) => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}acc/signed-url`, {
      params: {
        fileKey: key,
      },
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const dashboardAPI = async () => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}admin/dashboard`, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getBannersAPI = async (active = false) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}admin/dashboard-banner`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getUsersAPI = async (size, pageNumber) => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}admin/user-info`, {
      params: {
        size,
        pageNumber,
      },
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const getUsersIdAPI = async (userId) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}admin/user/id?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getPaymentDetailsAPI = async (userId) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}admin/user/payment-details?userId=${userId}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const updateBannerStatusAPI = async (bannerId, isActive) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_API_URL}admin/dashboard-banner`,
      {
        bannerId,
        isActive,
      },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const todaysSummaryAPI = async () => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}admin/today-summary`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getExchangesAPI = async () => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}admin/exchange`, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const getSiteWiseReportAPI = async (siteId) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}admin/sitewise-report?exchangeId=${siteId}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getTransactionsByIdAPI = async (transactionId) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}admin/transactions/${transactionId}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getLeadsAPI = async () => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}admin/leads`, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const updatePaymentDetailsAPI = async (id, payload) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_API_URL}admin/payment-details/${id}`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const addNewPaymentDetailsAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}admin/payment-details`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const deleteBannerAPI = async (bannerId) => {
  try {
    const { data } = await axios.delete(
      `${REACT_APP_API_URL}admin/dashboard-banner/${bannerId}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const addNewBannerAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}admin/dashboard-banner`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getRolesAPI = async () => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}auth/roles`, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const addAdminUserAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}auth/admin/user`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getAdminUsersAPI = async () => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}auth/admin/users`, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};

export const deleteAdminUserAPI = async (id, isDelete) => {
  try {
    const { data } = await axios.delete(
      `${REACT_APP_API_URL}auth/admin/${id}?isDelete=${isDelete}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const updateAdminUserAPI = async (id, payload) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_API_URL}auth/admin/${id}`,
      {
        ...payload,
      },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const manageCoinsAPI = async (payload) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}admin/user/deposit-withdraw-coins`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const changeAccountsPasswordAPI = async (id, password) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_API_URL}admin/user/account/password?userExchangeIdId=${id}&password=${password}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getUserIdDetailsAPI = async (id) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}admin/transactions/user-exchange/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const closeIdAPI = async (payload) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_API_URL}admin/user/account/status`,
      { ...payload },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

export const getAllIdsAPI = async () => {
  try {
    const { data } = await axios.get(`${REACT_APP_API_URL}acc/account`, {
      headers: {
        Authorization: `Bearer ${getTokenFromLocalStorage()}`,
      },
    });
    return data;
  } catch (error) {
    return error;
  }
};
export const searchUserAPI = async (payload) => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}admin/user/search?search=${payload}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getAdminHandlerAPI = async (
  size,
  pageNumber,
  adminUserName,
  mobileNumber
) => {
  try {
    const { data } = await axios.post(
      `${REACT_APP_API_URL}admin/handler/user-info?pageNumber=${pageNumber}&size=${size}`,
      adminUserName ? { adminUserName } : { mobileNumber },
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const putAdminHandlerAPI = async (adminUserName, users) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_API_URL}admin/handler/add`,
      { users },
      {
        params: { adminUserName },
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const getDefaultHandlerAPI = async () => {
  try {
    const { data } = await axios.get(
      `${REACT_APP_API_URL}admin/handler/default`,
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const updateDefaultHandlerAPI = async (adminUserName) => {
  try {
    const { data } = await axios.put(
      adminUserName != null
        ? `${REACT_APP_API_URL}admin/handler/default?adminUserName=${adminUserName}`
        : `${REACT_APP_API_URL}admin/handler/default`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};
export const reactivateAdminAPI = async (userId) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_API_URL}auth/admin/activate/${userId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getTokenFromLocalStorage()}`,
        },
      }
    );
    return data;
  } catch (error) {
    return error;
  }
};

/* export const deletePaymentDetailsAPI = async (id, paymentType) => {
  try {
    const { data } = await axios.put(
      `${REACT_APP_API_URL}admin/payment-details/${id}`,
     
    );
    return data;
  } catch (error) {
    return error;
  }
}; */
