import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import TopNavigation from "../../components/TopNavigation";
import { LoadingContext } from "../../context/LoadingContext";
import { getLeadsAPI } from "../../service/UserService";
export default function Leads() {
  const [leads, setLeads] = useState([]);
  const { setLoading } = useContext(LoadingContext);
  const getLeads = async () => {
    setLoading(true);
    const {
      data: { leads },
    } = await getLeadsAPI();
    setLeads(leads);
    setLoading(false);
  };
  useEffect(() => {
    getLeads();
  }, []);
  return (
    <>
      <TopNavigation isBackNav={true} title="Leads" />
      <div className="w-[100%] overflow-y-auto flex flex-col items-center justify-centert">
        {/* <div className="flex my-2 w-[98%] flex-row rounded-lg p-2 bg-[#1A1D20] items-center">
          <img
            className="h-[20px] w-[20px]"
            src={require("../../assets/img/search_icon.png")}
          />
          <input
            className="bg-transparent text-white w-[100%] outline-none ml-2 "
            placeholder="Name,Phone"
          />
        </div> */}
        <div className="h-[85vh] px-1 w-[100%] ">
          {leads.map(({ uid, name, mobileNumber, createdAt }, index) => (
            <div className="w-[100%] mt-2 text-gray-300  bg-[#1A1D20] rounded-lg">
              <div className="  px-2 border-b-[1px] border-b-gray-600 flex flex-row justify-between items-center">
                <div className="flex p-1 items-center">
                  <img
                    className="h-[20px] w-[20px] mr-1 "
                    src={require("../../assets/img/user_icon.png")}
                  />
                  <p>{name}</p>
                </div>
                <div className="flex p-1 items-center">
                  <img
                    className="h-[20px] w-[20px] mr-1"
                    src={require("../../assets/img/unlocked_icon.png")}
                  />
                  <p>{mobileNumber}</p>
                </div>
              </div>
              <div className="px-2 pb-2">
                <div className="w-[100%] mt-2 justify-between flex flex-row">
                  <p className="text-gray-500 text-sm font-light">
                    {new Date(createdAt).toLocaleString()}
                  </p>
                  <div className="flex flex-row"> <a
                    href={`https://wa.me/${mobileNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ cursor: 'pointer' }}
                  >
                    Chat
                  </a></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
