import React, { useEffect, useState } from "react";
import TopNavigation from "../../../components/TopNavigation";
import { getSignedUrlAPI, getUsersIdAPI } from "../../../service/UserService";

export default function UserIds(router) {
  const {
    history: {
      location: {
        state: { userId },
      },
    },
  } = router;
  const [userIds, setUserIds] = useState([]);
  const loadUserIds = async () => {
    const {
      data: { data },
    } = await getUsersIdAPI(userId);
    let data_ = data;
    for (let index = 0; index < data_.length; index++) {
      const {
        data: { url },
      } = await getSignedUrlAPI(data_[index].exchangeIconUrl);
      data_[index].exchangeIconUrl = url;
    }
    setUserIds(data_);
  };
  useEffect(() => {
    loadUserIds();
  }, []);
  return (
    <>
      <TopNavigation isBackNav={true} title="User Ids" />
      <div className="w-[100%] overflow-y-scroll flex flex-col items-center justify-centert">
        <div className="flex my-2 w-[98%] flex-row rounded-lg p-2 bg-[#1A1D20] items-center">
          <img
            className="h-[20px] w-[20px]"
            src={require("../../../assets/img/search_icon.png")}
          />
          <input
            className="bg-transparent w-[100%] outline-none ml-2 "
            placeholder="ID,Name,Phone"
          />
        </div>
        <div className="h-[85vh] px-1 w-[100%] ">
          {userIds.map(
            (
              {
                exchangeIconUrl,
                exchangeUrl,
                userName,
                createdAt,
                withdraw,
                deposit,
              },
              index
            ) => (
              <div className="w-[100%] ">
                <div className="flex self-center flex-row px-2 py-4 bg-[#23272b] items-center justify-between align-middle mt-2  w-[100%]">
                  <div className="w-[80%] flex flex-row align-middle items-center">
                    <img
                      className="w-14 mr-5 rounded-full"
                      src={exchangeIconUrl}
                    />
                    <div className="flex flex-col">
                      <span className="text-gray-300 flex ">
                        {exchangeUrl.replace("https://", "")}{" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-4 h-4 ml-2 mt-1"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                          />
                        </svg>
                      </span>
                      <span className="text-[#FFFFFF61] flex row text-sm">
                        User Name - {userName}
                      </span>
                      <span className="text-[#FFFFFF61] flex row text-sm">
                        Created On - {new Date(createdAt).toDateString()}
                      </span>
                      <span className="text-[#FFFFFF61] flex row text-sm">
                        DEPOSIT - {deposit}
                      </span>
                      <span className="text-[#FFFFFF61] flex row text-sm">
                        WITHDRAW - {withdraw}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}
