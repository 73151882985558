import React, { useEffect, useState } from "react";
import TopNavigation from "../../../components/TopNavigation";
import {
  getSignedUrlAPI,
  getTransactionsAPI,
  getUsersIdAPI,
} from "../../../service/UserService";

export default function UserTransactions(router) {
  const {
    history: {
      location: {
        state: { userId },
      },
    },
  } = router;
  const [userIds, setUserIds] = useState([]);
  const loadUserIds = async () => {
    const {
      data: {
        data: { content },
      },
    } = await getTransactionsAPI(50, 0, [], userId);
    setUserIds(content);
  };
  useEffect(() => {
    loadUserIds();
  }, []);
  return (
    <>
      <TopNavigation isBackNav={true} title="User Ids" />
      <div className="w-[100%] overflow-y-scroll flex flex-col items-center justify-centert">
        <div className="flex my-2 w-[98%] flex-row rounded-lg p-2 bg-[#1A1D20] items-center">
          <img
            className="h-[20px] w-[20px]"
            src={require("../../../assets/img/search_icon.png")}
          />
          <input
            className="bg-transparent w-[100%] outline-none ml-2 "
            placeholder="ID,Name,Phone"
          />
        </div>
        <div className="h-[85vh] px-1 w-[100%] ">
          {userIds.map(
            (
              {
                approvedCoins,
                coins,
                fromUserId,
                paymentProofs,
                paymentType,
                requestGenerated,
                requestValidated,
                toUserId,
                transactionId,
                transactionStatus,
                transactionType,
                validatedBy,
              },
              index
            ) => (
              <div className="flex self-center flex-row px-2 py-4  items-center justify-between align-middle mt-1 bg-[#212528]  w-[98%]">
                <div className="w-[80%] flex flex-row align-middle items-center">
                  <div className="flex flex-col">
                    <span className="text-gray-300">
                      {transactionType.charAt(0) +
                        transactionType
                          .slice(1)
                          .toLowerCase()
                          .replaceAll("_", " ")}
                    </span>

                    <span className="text-[#FFFFFF61] text-sm">
                      {new Date(requestGenerated).toDateString()}
                    </span>
                  </div>
                </div>
                <div className="flex flex-col text-right px-2">
                  <p className="text-gray-200">
                    {transactionType == "WITHDRAW_FROM_WALLET" ||
                    transactionType == "DEPOSIT_TO_ID" ||
                    transactionType == "CREATE_ID"
                      ? "-"
                      : "+"}
                    {transactionStatus == "PENDING" ? coins : approvedCoins}
                  </p>
                  <p
                    className={
                      transactionStatus == "PENDING"
                        ? "text-yellow-500"
                        : transactionStatus == "REJECTED"
                        ? "text-red-400"
                        : "text-green-400"
                    }
                  >
                    {transactionStatus.toUpperCase()}
                  </p>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
}
