import AddNewBank from "../pages/HomePages/BankManagement/AddNewBank";
import AddNewBanner from "../components/AddNewBanner";
import AddNewAdmin from "../pages/HomePages/AdminManagement/AddNewAdmin";
import AdminManagement from "../pages/HomePages/AdminManagement/AdminManagement";
import Dashboard from "../pages/HomePages/Dashboard";
import HomePage from "../pages/HomePages/HomePage";
import ChangeAccountPassword from "../pages/HomePages/IdManagement/ChangeAccountPassword";
import CoinDepositWithdraw from "../pages/HomePages/IdManagement/CoinDepositWithdraw";
import IdDetails from "../pages/HomePages/IdManagement/IdDetails";
import Leads from "../pages/HomePages/Leads";
import ManageBanners from "../pages/HomePages/ManageBanners";
import MyUsers from "../pages/HomePages/MyUsers";
import Notification from "../pages/HomePages/Notification";
import PayoutScreenShots from "../pages/HomePages/PayoutScreenShots";
import SiteWiseReport from "../pages/HomePages/SiteWiseReport";
import Transactions from "../pages/HomePages/Transactions";
import UserManagement from "../pages/HomePages/UserManagement/UserManagement";
import Login from "../pages/Login";
import UserIds from "../pages/HomePages/UserManagement/UserIds";
import UserTransactions from "../pages/HomePages/UserManagement/UserTransactions";
import BankList from "../pages/HomePages/BankManagement/BankList";
import UserToAdminManage from "../pages/HomePages/AdminManagement/UserToAdminManage";

export const appRoutes = [
  {
    path: "/home",
    element: <HomePage />,
    roles: ["all"],
    hasRole: false,
  },
  {
    path: "/admin/handler",
    element: <UserToAdminManage />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
  },
  {
    path: "/",
    element: <HomePage />,
    roles: ["all"],
    hasRole: false,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
    roles: ["all"],
    hasRole: false,
    showInSideBar: true,
    showOnHome: true,
    icon: require("../assets/img/dashboard_icon.png"),
    title: "Dashboard",
  },
  {
    path: "/admin/admin-management",
    element: <AdminManagement />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
  },
  {
    path: "/notifications",
    element: <Notification />,
    roles: ["all"],
    hasRole: false,
  },
  {
    path: "/payout-screenshots/:id",
    element: <PayoutScreenShots />,
    roles: [
      "ROLE_ADMIN",
      "ROLE_BANK_DEPOSIT",
      "ROLE_PAYTM_DEPOSIT",
      "ROLE_PHONEPE_DEPOSIT",
      "ROLE_GPAY_DEPOSIT",
      "ROLE_BHIM_DEPOSIT",
      "ROLE_BANK_WITHDRAW",
      "ROLE_PAYTM_WITHDRAW",
      "ROLE_PHONEPE_WITHDRAW",
      "ROLE_GPAY_WITHDRAW",
      "ROLE_BHIM_WITHDRAW",
      "ROLE_DEPOSIT",
      "ROLE_WITHDRAW",
      "ROLE_EXCHANGE_MANAGE",
      "ROLE_G10K",
    ],
    hasRole: false,
    showInSideBar: false,
    showOnHome: false,
    title: "Payout Screenshots",
  },
  {
    path: "/all-transactions",
    element: <Transactions />,
    roles: ["all"],
    hasRole: false,
    showInSideBar: true,
    showOnHome: true,
    icon: require("../assets/img/dashboard_icon_9.png"),
    title: "All Transactions",
  },
  {
    path: "/my-users",
    element: <MyUsers />,
    roles: ["all"],
    hasRole: false,
    showInSideBar: true,
    showOnHome: true,
    icon: require("../assets/img/dashboard_icon_1.png"),
    title: "My Users",
  },
  {
    path: "/leads",
    element: <Leads />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
    showInSideBar: true,
    showOnHome: true,
    icon: require("../assets/img/dashboard_icon_2.png"),
    title: "Leads",
  },
  {
    path: "/sitewise-report",
    element: <SiteWiseReport />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
    showInSideBar: true,
    showOnHome: true,
    title: "SiteWise Report",
    icon: require("../assets/img/dashboard_icon_3.png"),
  },
  {
    path: "/payout-screenshots",
    element: <PayoutScreenShots />,
    roles: [
      "ROLE_ADMIN",
      "ROLE_BANK_DEPOSIT",
      "ROLE_PAYTM_DEPOSIT",
      "ROLE_PHONEPE_DEPOSIT",
      "ROLE_GPAY_DEPOSIT",
      "ROLE_BHIM_DEPOSIT",
      "ROLE_BANK_WITHDRAW",
      "ROLE_PAYTM_WITHDRAW",
      "ROLE_PHONEPE_WITHDRAW",
      "ROLE_GPAY_WITHDRAW",
      "ROLE_BHIM_WITHDRAW",
      "ROLE_DEPOSIT",
      "ROLE_WITHDRAW",
      "ROLE_EXCHANGE_MANAGE",
      "ROLE_G10K",
    ],
    hasRole: false,
    showInSideBar: true,
    showOnHome: true,
    title: "Payout Screenshots",
    icon: require("../assets/img/dashboard_icon_6.png"),
  },
  {
    path: "/manage-banners",
    element: <ManageBanners />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
    showInSideBar: true,
    showOnHome: true,
    title: "Manage Banners",
    icon: require("../assets/img/dashboard_icon_8.png"),
  },
  {
    path: "/user-ids",
    element: <UserIds />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
  },
  {
    path: "/bank-details",
    element: <BankList />,
    roles: ["ROLE_ADMIN", "ROLE_PAYMENT_MANAGE"],
    hasRole: false,
    showInSideBar: true,
    showOnHome: false,
    title: "Bank Details",
    icon: require("../assets/img/dashboard_icon_5.png"),
  },
  {
    path: "/bank-details/:id",
    element: <AddNewBank />,
    roles: ["ROLE_ADMIN", "ROLE_PAYMENT_MANAGE"],
    hasRole: false,
    showInSideBar: false,
    showOnHome: false,
    title: "Bank Details",
  },
  {
    path: "/user-transactions",
    element: <UserTransactions />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
  },
  {
    path: "/userid/ids",
    element: <UserIds />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
  },
  {
    path: "/user-management",
    element: <UserManagement />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
  },
  {
    path: "/userid/transactions",
    element: <UserTransactions />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
  },
  {
    path: "/id/change-password",
    element: <ChangeAccountPassword />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
  },
  {
    path: "/id/details",
    element: <IdDetails />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
  },
  {
    path: "/id/account-deposit-withdraw",
    element: <CoinDepositWithdraw />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
  },
  {
    path: "/addNewBank",
    element: <AddNewBank />,
    roles: ["ROLE_ADMIN", "ROLE_PAYMENT_MANAGE"],
    hasRole: false,
  },
  {
    path: "/addNewBanner",
    element: <AddNewBanner />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
  },
  {
    path: "/admin-management",
    element: <AdminManagement />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
    showInSideBar: true,
    showOnHome: true,
    title: "Admin Management",
    icon: require("../assets/img/dashboard_icon_3.png"),
  },
  {
    path: "/admin-management/add-new-admin",
    element: <AddNewAdmin />,
    roles: ["ROLE_ADMIN"],
    hasRole: false,
  },
  {
    path: "/",
    element: <Login />,
    roles: ["all"],
    hasRole: true,
    isAuthPage: true,
  },
  {
    path: "/login",
    element: <Login />,
    roles: ["all"],
    hasRole: true,
    isAuthPage: true,
  },
];
