import { Switch } from "@material-tailwind/react";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TopNavigation from "../../components/TopNavigation";
import NoData from "../../components/UX/NoData";
import CustomModal from "../../components/CustomModal";
import { toast } from "react-toastify";
import {
  deleteBannerAPI,
  getBannersAPI,
  getSignedUrlAPI,
  updateBannerStatusAPI,
} from "../../service/UserService";
import { LoadingContext } from "../../context/LoadingContext";
export default function ManageBanners({ currentPage }) {
  const [currentActivePage, setCurrentActivePage] = useState(
    currentPage ? currentPage : 0
  );
  const [banners, setBanners] = useState([]);
  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  const [createIdsList, setCreateIdsList] = useState([]);
  useEffect(() => {
    getBanners();
  }, []);
  const getBanners = async () => {
    setLoading(true);
    const {
      data: { banners },
    } = await getBannersAPI();
    let banners_ = banners;
    for (let i = 0; i < banners_.length; i++) {
      if (banners_[i].bannerUrl && banners_[i].bannerUrl != null) {
        try {
          const {
            data: { url },
          } = await getSignedUrlAPI(banners_[i].bannerUrl);
          banners_[i].bannerUrl = url ? url : "";
        } catch (e) {}
      }
    }
    setLoading(false);
    setBanners(banners);
  };
  const handleBannerStatus = async (bannerId, isActive) => {
    var banners_ = banners;
    var bannerStatus = banners_.filter(
      (banner) => banner.bannerId === bannerId
    )[0].isActive;

    const { data, statusCode } = await updateBannerStatusAPI(
      bannerId,
      !bannerStatus
    );

    if (statusCode === 200) {
      toast("Banner status updated successfully", {
        type: "success",
      });
      getBanners();
    }
  };
  const handleBannerDelete = async (bannerId) => {
    const { data, statusCode, message } = deleteBannerAPI(bannerId);
    if (statusCode == 200) {
      toast("Banner delete successfully", {
        type: "success",
      });
      getBanners();
    } else {
      toast(message, {
        type: "error",
      });
      getBanners();
    }
  };
  return (
    <>
      <TopNavigation
        isBackNav={true}
        title={"Manage Banners"}
        showNotification={true}
      />
      <div className="container h-[91.5vh]">
        <div className="flex justify-end w-[100%] ">
          <Link to={"/addNewBanner"}>
            <div className="px-3 cursor-pointer py-1 bg-[#FFBF44] my-3 text-white rounded-md">
              Add New Banner
            </div>
          </Link>
        </div>
        <div className="flex mt-2 flex-row items-center bg-[#1D2023] rounded-full text-white inner_shadow">
          <span
            onClick={() => setCurrentActivePage(0)}
            className={`rounded-full cursor-pointer ${
              currentActivePage == 0 ? "bg-[#FFBF44]" : ""
            } w-[50%] p-3 text-center`}
          >
            Active banners
          </span>
          <span
            onClick={() => setCurrentActivePage(1)}
            className={`rounded-full cursor-pointer ${
              currentActivePage == 1 ? "bg-[#FFBF44]" : ""
            } w-[50%] p-3 text-center`}
          >
            All Banners
          </span>
        </div>

        <div className="mt-5">
          {banners.length == 0 ? (
            <NoData />
          ) : (
            banners
              .filter((banner) =>
                currentActivePage == 0 ? banner.isActive : banner
              )
              .sort((a, b) => b.bannerId - a.bannerId)
              .map(
                (
                  {
                    bannerId,
                    bannerPriority,
                    bannerUrl,
                    fromDate,
                    isActive,
                    toDate,
                  },
                  index
                ) => {
                  return (
                    <div className="flex self-center flex-row px-2 py-4  items-center justify-between align-middle mt-1 bg-[#212528]  w-[100%]">
                      <div className="w-[80%] flex flex-row align-middle items-center">
                        <img className="w-1/3 mr-5" src={bannerUrl} />
                      </div>
                      <div className="flex flex-row">
                        <span
                          onClick={() => handleBannerDelete(bannerId)}
                          className="bg-[#DF4C44] mr-5 cursor-pointer ml-1 rounded-lg  px-2 py-1 text-center  text-white text-sm"
                        >
                          Delete
                        </span>
                        <Switch
                          id={index}
                          label={isActive ? "Active" : "Disabled"}
                          checked={isActive}
                          onChange={() =>
                            handleBannerStatus(bannerId, isActive)
                          }
                        />
                      </div>
                    </div>
                  );
                }
              )
          )}
        </div>
      </div>
    </>
  );
}
