import React, { useContext, useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { BarChart } from "../../components/Charts/BarChart";
import TopNavigation from "../../components/TopNavigation";
import { LoadingContext } from "../../context/LoadingContext";
import { dashboardAPI } from "../../service/UserService";

const DepositAndWithdraw = (setCurrentPage, currentPage, dashboardData) => (
  <>
    <p className="mt-5">Deposits - Till date</p>
    <h1 className="text-3xl">{dashboardData?.depositsTillDate}</h1>
    <div className="w-[100%] justify-between flex flex-row">
      <p>Withdraw</p>
      <p>{dashboardData?.withdrawTillDate}</p>
    </div>
    <div className="w-[100%] justify-between flex flex-row">
      <p>P/L</p>
      <p>{dashboardData?.palTillDate}</p>
    </div>
    <div className="w-[100%] justify-between flex flex-row">
      <p>Wallet Balance</p>
      <p>{dashboardData?.walletBalance}</p>
    </div>
    <div className="rounded-xl flex flex-col bg-[#1A1D20] mt-5">
      <div className="flex flex-row justify-between">
        <div
          onClick={() => setCurrentPage(0)}
          style={{
            backgroundColor: currentPage === 0 ? "#FFBF44" : "#1A1D20",
          }}
          className="w-[50%] text-center p-2 rounded-lg"
        >
          <p className="text-white text-sm">Deposits & Withdrawal</p>
        </div>
        <div
          onClick={() => setCurrentPage(1)}
          style={{
            backgroundColor: currentPage === 1 ? "#FFBF44" : "#1A1D20",
          }}
          className="w-[50%] text-center p-2 rounded-lg"
        >
          <p className="text-white text-sm">Clients</p>
        </div>
      </div>
      <div className="w-[100%] flex mt-1 items-center justify-center">
        <p>Total</p>
      </div>
      <div className="p-2">
      <div className="flex my-2  flex-row bg-[#0F1113] py-2 w-[100%] rounded-lg">
          <div className=" text-white text-center  self-center  -rotate-90">
            <p className="text-sm mx-3">Today</p>
          </div>
          <div className="flex text-sm flex-col w-[85%] mr-2">
            <div className=" justify-between flex flex-row">
              <p>Deposit</p>
              {dashboardData?.adminDashboardPALResponseToday?.deposit}
            </div>
            <div className=" justify-between flex flex-row border-b-[1px]">
              <p>Withdraw</p>
              {dashboardData?.adminDashboardPALResponseToday?.withdraw}
            </div>
            <div className="justify-between flex flex-row mt-1">
              <p>P/L</p>
              {dashboardData?.adminDashboardPALResponseToday?.pal}
            </div>
          </div>
        </div>
        <div className="flex my-2 flex-row bg-[#0F1113] py-2 w-[100%] rounded-lg">
          <div className=" text-white text-center  self-center  -rotate-90">
            <p className="text-sm">Yesterday</p>
          </div>
          <div className="flex text-sm flex-col w-[85%] mr-2">
            <div className=" justify-between flex flex-row">
              <p>Deposit</p>
              {dashboardData?.adminDashboardPALResponseYesterday?.deposit}
            </div>
            <div className=" justify-between flex flex-row border-b-[1px]">
              <p>Withdraw</p>
              {dashboardData?.adminDashboardPALResponseYesterday?.withdraw}
            </div>
            <div className="justify-between flex flex-row mt-1">
              <p>P/L</p>
              {dashboardData?.adminDashboardPALResponseYesterday?.pal}
            </div>
          </div>
        </div>
        <div className="flex my-2 flex-row bg-[#0F1113] py-2 w-[100%] rounded-lg">
          <div className=" text-white text-center  self-center  -rotate-90">
            <p className="text-sm">This Week</p>
          </div>
          <div className="flex text-sm flex-col w-[85%] mr-2">
            <div className=" justify-between flex flex-row">
              <p>Deposit</p>
              {dashboardData?.adminDashboardPALResponseThisWeek?.deposit}
            </div>
            <div className=" justify-between flex flex-row border-b-[1px]">
              <p>Withdraw</p>
              {dashboardData?.adminDashboardPALResponseThisWeek?.withdraw}
            </div>
            <div className="justify-between flex flex-row mt-1">
              <p>P/L</p>
              {dashboardData?.adminDashboardPALResponseThisWeek?.pal}
            </div>
          </div>
        </div>
        <div className="flex my-2 flex-row bg-[#0F1113] py-2 w-[100%] rounded-lg">
          <div className=" text-white text-center  self-center  -rotate-90">
            <p className="text-sm">Last Week</p>
          </div>
          <div className="flex text-sm flex-col w-[85%] mr-2">
            <div className=" justify-between flex flex-row">
              <p>Deposit</p>
              {dashboardData?.adminDashboardPALResponseLastWeek?.deposit}
            </div>
            <div className=" justify-between flex flex-row border-b-[1px]">
              <p>Withdraw</p>
              {dashboardData?.adminDashboardPALResponseLastWeek?.withdraw}
            </div>
            <div className="justify-between flex flex-row mt-1">
              <p>P/L</p>
              {dashboardData?.adminDashboardPALResponseLastWeek?.pal}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const Clients = (setCurrentPage, currentPage, dashboardData) => (
  <>
    <p className="mt-5">Clients - Till date</p>
    <h1 className="text-3xl">{dashboardData.clientsTillDate}</h1>

    <div className="rounded-xl flex flex-col bg-[#1A1D20] mt-5">
      <div className="flex flex-row justify-between">
        <div
          onClick={() => setCurrentPage(0)}
          style={{
            backgroundColor: currentPage === 0 ? "#FFBF44" : "#1A1D20",
          }}
          className="w-[50%] text-center p-2 rounded-lg"
        >
          <p className="text-white text-sm">Deposits & Withdrawal</p>
        </div>
        <div
          onClick={() => setCurrentPage(1)}
          style={{
            backgroundColor: currentPage === 1 ? "#FFBF44" : "#1A1D20",
          }}
          className="w-[50%] text-center p-2 rounded-lg"
        >
          <p className="text-white text-sm">Clients</p>
        </div>
      </div>
      <div className="w-[100%] flex mt-1 items-center justify-center">
        <p>Total</p>
      </div>
      <div className="p-2">
        <div className="flex my-2  flex-row bg-[#0F1113] py-2 w-[100%] rounded-lg">
          <div className=" text-white text-center  self-center  -rotate-90">
            <p className="text-sm mx-3">Today</p>
          </div>
          <div className="flex text-sm flex-col w-[85%] mr-2">
            <div className=" justify-between flex flex-row border-b-[1px]">
              <p>New Clients</p>
              <p>
                {dashboardData.adminDashboardClientResponseToday.newClients}
              </p>
            </div>
            <div className="justify-between flex flex-row mt-1">
              <p>Deposits By </p>
              <p>
                {dashboardData.adminDashboardClientResponseToday.depositsBy}
              </p>
            </div>
          </div>
        </div>
        <div className="flex my-2 flex-row bg-[#0F1113] py-2 w-[100%] rounded-lg">
          <div className=" text-white text-center  self-center h-[15%] -rotate-90">
            <p className="text-sm">Yesterday</p>
          </div>
          <div className="flex text-sm flex-col w-[85%] mr-2">
            <div className=" justify-between flex flex-row border-b-[1px]">
              <p>New Clients</p>
              <p>
                {dashboardData.adminDashboardClientResponseYesterday.newClients}
              </p>
            </div>
            <div className="justify-between flex flex-row mt-1">
              <p>Deposits By </p>
              {dashboardData.adminDashboardClientResponseYesterday.depositsBy}
            </div>
          </div>
        </div>
        <div className="flex my-2 flex-row bg-[#0F1113] py-2 w-[100%] rounded-lg">
          <div className=" text-white text-center  self-center h-[15%] -rotate-90">
            <p className="text-sm">This Week</p>
          </div>
          <div className="flex text-sm flex-col w-[85%] mr-2">
            <div className=" justify-between flex flex-row border-b-[1px]">
              <p>New Clients</p>
              {dashboardData.adminDashboardClientResponseThisWeek.newClients}
            </div>
            <div className="justify-between flex flex-row mt-1">
              <p>Deposits By </p>
              {dashboardData.adminDashboardClientResponseThisWeek.depositsBy}
            </div>
          </div>
        </div>
        <div className="flex my-2 flex-row bg-[#0F1113] py-2 w-[100%] rounded-lg">
          <div className=" text-white text-center  self-center h-[15%] -rotate-90">
            <p className="text-sm">Last Week</p>
          </div>
          <div className="flex text-sm flex-col w-[85%] mr-2">
            <div className=" justify-between flex flex-row border-b-[1px]">
              <p>New Clients</p>
              {dashboardData.adminDashboardClientResponseLastWeek.newClients}
            </div>
            <div className="justify-between flex flex-row mt-1">
              <p>Deposits By </p>
              {dashboardData.adminDashboardClientResponseLastWeek.depositsBy}
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
);

const Charts = (setCurrentChart, currentChart, chartData) => (
  <div className="rounded-xl h-auto w-[99%] mb-5  flex flex-col bg-[#1A1D20] mt-5">
    <div className="flex flex-row justify-between">
      <div
        onClick={() => setCurrentChart(0)}
        style={{
          backgroundColor: currentChart === 0 ? "#FFBF44" : "#1A1D20",
        }}
        className="w-[50%] text-center p-2 rounded-lg"
      >
        <p className="text-white text-sm">D/W From Wallet</p>
      </div>
      <div
        onClick={() => setCurrentChart(1)}
        style={{
          backgroundColor: currentChart === 1 ? "#FFBF44" : "#1A1D20",
        }}
        className="w-[50%] text-center p-2 rounded-lg"
      >
        <p className="text-white text-sm">New Users</p>
      </div>
    </div>
    <BarChart chartData={chartData} />
  </div>
);

export default function Dashboard() {
  const [currentPage, setCurrentPage] = useState(0);
  const [dashboardData, setDashboardData] = useState({});
  const { setLoading } = useContext(LoadingContext);
  const [chartData, setChartData] = useState({
    labels: ["Today", "Yesterday", "This Week", "Last Week"],
    datasets: [
      {
        label: "Price in INR",
        data: [
          dashboardData?.adminDashboardPALResponseToday?.deposit,
          dashboardData?.adminDashboardPALResponseYesterday?.deposit,
          dashboardData?.adminDashboardPALResponseThisWeek?.deposit,
          dashboardData?.adminDashboardPALResponseLastWeek?.deposit,
        ],
        backgroundColor: ["#ffbb11", "#ecf0f1", "#50AF95", "#f3ba2f"],
      },
    ],
  });

  const [currentChart, setCurrentChart] = useState(0);

  const getDashboardData = async () => {
    setLoading(true);
    const {
      data: { data },
    } = await dashboardAPI();
    setDashboardData(data);
    if (currentChart == 0) {
      setChartData({
        ...chartData,
        datasets: [
          {
            ...chartData.datasets[0],

            data: [
              data?.adminDashboardPALResponseToday?.deposit,
              data?.adminDashboardPALResponseYesterday?.deposit,
              data?.adminDashboardPALResponseThisWeek?.deposit,
              data?.adminDashboardPALResponseLastWeek?.deposit,
            ],
          },
        ],
      });
    } else {
      setChartData({
        ...chartData,
        datasets: [
          {
            ...chartData.datasets[0],
            label: "Clients",
            data: [
              data?.adminDashboardClientResponseToday?.newClients,
              data?.adminDashboardClientResponseYesterday?.newClients,
              data?.adminDashboardClientResponseThisWeek?.newClients,
              data?.adminDashboardClientResponseLastWeek?.newClients,
            ],
          },
        ],
      });
    }
    setLoading(false);
  };
  useEffect(() => {
    getDashboardData();
  }, [currentChart]);
  return (
    <>
      <TopNavigation isBackNav={true} title="Dashboard" />
      <div className="flex flex-col  w-[100%] text-gray-300 text-center px-1">
        {currentPage === 0
          ? DepositAndWithdraw(setCurrentPage, currentPage, dashboardData)
          : Clients(setCurrentPage, currentPage, dashboardData)}
      </div>
      {Charts(setCurrentChart, currentChart, chartData)}
    </>
  );
}
