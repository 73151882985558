import {
  Popover,
  PopoverContent,
  PopoverHandler,
  Switch,
} from "@material-tailwind/react";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast, useToast } from "react-toastify";
import CustomModal from "../../components/CustomModal";
import TopNavigation from "../../components/TopNavigation";
import Table from "../../components/UX/Table/Table";
import { LoadingContext } from "../../context/LoadingContext";
import {
  closeIdAPI,
  getAllIdsAPI,
  getExchangesAPI,
  getSignedUrlAPI,
  getSiteWiseReportAPI,
} from "../../service/UserService";
export default function SiteWiseReport() {
  const [exchanges, setExchanges] = useState([]);
  const { setLoading } = useContext(LoadingContext);
  const [selectedSite, setSelectedSite] = useState(null);
  const [siteWiseReport, setSiteWiseReport] = useState({});
  const [users, setUsers] = useState([{}]);
  const [setMyIds, setSetMyIds] = useState([]);
  const history = useNavigate();
  const [selectedId, setSelectedId] = useState(null);
  const getExchanges = async () => {
    const { data } = await getExchangesAPI();
    var exchanges = data["Exchange Data"];
    for (let i = 0; i < exchanges.length; i++) {
      try {
        const {
          data: { url },
        } = await getSignedUrlAPI(exchanges[i].exchangeIconUrl);
        exchanges[i].exchangeIconUrl = url ? url : "";
      } catch (e) {}
    }
    setExchanges(exchanges);
  };
  useEffect(() => {
    getExchanges();
  }, []);
  const getSiteWiseReport = async () => {
    setLoading(true);
    setTimeout(() => setLoading(false), 3000);
    const { data } = await getSiteWiseReportAPI(selectedSite.exchangeId);
    setSiteWiseReport(data);
  };
  useEffect(() => {
    getSiteWiseReport();
  }, [selectedSite]);
  const handleCloseId = (data) => {
    setIsCloseIdModalOpen(true);
    setSelectedId(data);
  };
  const [isCloseIdModalOpen, setIsCloseIdModalOpen] = useState(false);
  const [activeBets, setActiveBets] = useState(false);
  const [reasonForClousre, setReasonForClousre] = useState("");
  const closeId = async () => {
    if (reasonForClousre == "") {
      toast("Please enter reason for closure", {
        type: "error",
      });
      return;
    }
    setLoading(true);
    const { data, statusCode } = await closeIdAPI({
      userExchangeIdId: selectedId.id,
      reasonForClosure: reasonForClousre,
      activeBet: activeBets,
      service: selectedId.exchangeName,
    });
    if (statusCode == 200) {
      getAllIds();
      setIsCloseIdModalOpen(false);
      toast("Id closed successfully", {
        type: "success",
      });
    } else {
      toast(data.message, {
        type: "error",
      });
    }
    setLoading(false);
  };
  const getAllIds = async () => {
    setLoading(true);
    const {
      data: { User_IDs },
    } = await getAllIdsAPI();
    let ids = User_IDs;
    for (let index = 0; index < ids.length; index++) {
      const {
        data: { url },
      } = await getSignedUrlAPI(ids[index].exchangeIconUrl);
      ids[index].exchangeIconUrl = url;
    }
    setLoading(false);
    setMyIds(ids);
  };
  const [columns, setColumns] = useState([
    {
      Header: "User Name",
      accessor: "userNameExchange",
    },
    {
      Header: "Password",
      accessor: "password",
    },
    {
      Header: "Balance",
      accessor: "balance",
    },

    {
      Header: "Actions",
      accessor: "requestGenerated",
      Cell: (row) => (
        <div className="flex flex-row">
          <span
            onClick={() => {
              let data = row.data[row.row.index];
              history("/id/account-deposit-withdraw", {
                state: {
                  exchangeData: {
                    isWithdraw: false,
                    userId: data.userId,
                    id: parseInt(data.userExchangeIdId),
                    userNameExchange: data.userNameExchange,
                  },
                },
              });
            }}
            className="bg-[#0E863E] cursor-pointer rounded-[50px]  p-1 text-center w-[30px] h-[30px] text-white"
          >
            D
          </span>
          <span
            onClick={() => {
              let data = row.data[row.row.index];
              history("/id/account-deposit-withdraw", {
                state: {
                  exchangeData: {
                    isWithdraw: true,
                    userId: data.userId,
                    id: parseInt(data.userExchangeIdId),
                    userNameExchange: data.userNameExchange,
                  },
                },
              });
            }}
            className="bg-[#DF4C44] cursor-pointer ml-1 rounded-[50px]  p-1 text-center w-[30px] h-[30px] text-white"
          >
            W
          </span>
          <span className=" cursor-pointer ml-1 rounded-[50px]  p-1 text-center w-[30px] h-[30px] text-white">
            <Popover
              animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0, y: 25 },
              }}
              offset={10}
              placement="bottom-end"
            >
              <PopoverHandler>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.625 12a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H8.25m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0H12m4.125 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm0 0h-.375M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </PopoverHandler>
              <PopoverContent className="bg-[#2d3236] outline-none border-none">
                <div className="flex flex-col">
                  <span
                    onClick={() => {
                      let data = row.data[row.row.index];
                      history("/id/account-deposit-withdraw", {
                        state: {
                          exchangeData: {
                            isWithdraw: false,
                            userId: data.userId,
                            id: parseInt(data.userExchangeIdId),
                            userNameExchange: data.userNameExchange,
                          },
                        },
                      });
                    }}
                    className="text-gray-300 p-2 text-left cursor-pointer"
                  >
                    Deposit
                  </span>
                  <span
                    onClick={() => {
                      let data = row.data[row.row.index];
                      history("/id/account-deposit-withdraw", {
                        state: {
                          exchangeData: {
                            isWithdraw: true,
                            userId: data.userId,
                            id: parseInt(data.userExchangeIdId),
                            userNameExchange: data.userNameExchange,
                          },
                        },
                      });
                    }}
                    className="text-gray-300 p-2 text-left cursor-pointer"
                  >
                    Withdraw
                  </span>
                  <span
                    onClick={() => {
                      let data = row.data[row.row.index];
                      history("/id/details", {
                        state: {
                          exchangeData: {
                            exchangeIconUrl: data.exchangeIconUrl,
                            exchangeUrl: data.exchangeUrl,
                            userNameExchange: data.userNameExchange,
                            balance: data.balance,
                            id: parseInt(data.userExchangeIdId),
                            userId: data.userId,
                            userIdExchange: data.userIdExchange,
                            exchangeName: data.exchangeName,
                            password: data.password,
                          },
                        },
                      });
                    }}
                    className="text-gray-300 p-2 text-left cursor-pointer"
                  >
                    View Txns/Balance
                  </span>
                  <span
                    onClick={() => {
                      let data = row.data[row.row.index];
                      history("/id/change-password", {
                        state: {
                          exchangeData: {
                            exchangeIconUrl: data.exchangeIconUrl,
                            exchangeUrl: data.exchangeUrl,
                            userNameExchange: data.userNameExchange,
                            balance: data.balance,
                            id: parseInt(data.userExchangeIdId),
                            userId: data.userId,
                            userIdExchange: data.userIdExchange,
                            exchangeName: data.exchangeName,
                            password: data.password,
                          },
                        },
                      });
                    }}
                    className="text-gray-300 p-2 text-left cursor-pointer"
                  >
                    Change Password
                  </span>
                  <span
                    onClick={() => {
                      let data = row.data[row.row.index];
                      handleCloseId({
                        exchangeIconUrl: data.exchangeIconUrl,
                        exchangeUrl: data.exchangeUrl,
                        userNameExchange: data.userNameExchange,
                        balance: data.balance,
                        id: parseInt(data.userExchangeIdId),
                        userId: data.userId,
                        userIdExchange: data.userIdExchange,
                        exchangeName: data.exchangeName,
                        password: data.password,
                      });
                    }}
                    className="text-gray-300 p-2 text-left cursor-pointer"
                  >
                    Close ID
                  </span>
                </div>
              </PopoverContent>
            </Popover>
          </span>
        </div>
      ),
    },
  ]);

  return (
    <>
      <TopNavigation isBackNav={true} title="Sitewise Report" />
      <div className="h-[89vh] w-[100%]  overflow-x-hidden">
        <CustomModal
          isOpen={isCloseIdModalOpen}
          onClose={setIsCloseIdModalOpen}
        >
          <div className="flex flex-col text-white items-center justify-center">
            <h1 className="text-xl font-bold">Confirm Before You Proceed</h1>
            <span className="w-[100%] flex flex-row justify-between mt-5">
              No active bets
              <Switch
                value={activeBets}
                onChange={(e) => setActiveBets(e.target.value)}
              />
            </span>
            <textarea
              onChange={(e) => setReasonForClousre(e.target.value)}
              className="mt-5 bg-[#1D2023]"
              placeholder="Reason For Closure"
            />
            <button onClick={closeId} className="blue_button mt-5 py-2 px-5">
              Submit
            </button>
          </div>
        </CustomModal>
        {selectedSite ? (
          <div className="flex flex-col items-center px-2 mt-2">
            <img
              className="max-w-[20%] mt-5"
              src={selectedSite.exchangeIconUrl}
            />
            <p className="text-gray-300 mt-2 text-xl">
              {selectedSite.exchangeName}
            </p>
            <div className="w-[100%] mt-2 text-gray-300  bg-[#1A1D20] ">
              <div className="  px-2 border-b-[1px] border-b-gray-600 flex flex-row justify-between items-center">
                <p>Deposit</p>
                <p>{siteWiseReport.deposit}</p>
              </div>
              <div className="  px-2 border-b-[1px] border-b-gray-600 flex flex-row justify-between items-center">
                <p>Ids</p>
                <p>{siteWiseReport.ids}</p>
              </div>
              <div className="  px-2 border-b-[1px] border-b-gray-600 flex flex-row justify-between items-center">
                <p>Users</p>
                <p>{siteWiseReport.users}</p>
              </div>
              <div className="  px-2 border-b-[1px] border-b-gray-600 flex flex-row justify-between items-center">
                <p>Withdraw</p>
                <p>{siteWiseReport.withdraw}</p>
              </div>
            </div>
          </div>
        ) : (
          exchanges.map(
            ({
              exchangeId,
              exchangeName,
              isActive,
              exchangeAdminUrl,
              exchangeIconUrl,
            }) => (
              <div
                onClick={() =>
                  setSelectedSite({
                    exchangeId,
                    exchangeName,
                    isActive,
                    exchangeAdminUrl,
                    exchangeIconUrl,
                  })
                }
                className="flex cursor-pointer flex-row w-[100%] m-2 justify-between items-center px-5 py-1 border-b-[1px] border-b-gray-600"
              >
                <p className="text-gray-300">{exchangeName}</p>
                <p className="text-gray-400 text-sm">{exchangeAdminUrl}</p>
              </div>
            )
          )
        )}
      </div>
    </>
  );
}
