import axios from "axios";
import { REACT_APP_API_URL } from "../utils/config";

export const loginAPI = async (name, password) => {
  try {
    const { data } = await axios.post(`${REACT_APP_API_URL}auth/login/admin`, {
      name,
      password,
    });
    return data;
  } catch (error) {
    return error;
  }
};


