import React, { useState } from "react";
import { useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TopNavigation from "../../../components/TopNavigation";
import { LoadingContext } from "../../../context/LoadingContext";
import { UserContext } from "../../../context/UserContext";
import { manageCoinsAPI } from "../../../service/UserService";

export default function CoinDepositWithdraw(router) {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    state: {
      exchangeData: {
        exchangeIconUrl,
        exchangeUrl,
        userNameExchange,
        balance,
        id,
        userId,
        userIdExchange,
        exchangeName,
        isWithdraw,
      },
    },
  } = location;
  const [coins, setCoins] = useState(1000);
  const { setIsLoading } = useContext(LoadingContext);
  const handleSubmit = async () => {
    if (!coins)
      return toast("Please fill all the required fields", {
        type: "error",
      });
    setIsLoading(true);
    const { data, statusCode, response, message } = await manageCoinsAPI({
      userExchangeIdId: id,
      creditType: isWithdraw ? "WITHDRAW" : "DEPOSIT",
      credit: coins,
    });

    if (statusCode == 200) {
      toast(
        isWithdraw
          ? "Coins Withdraw Successfully"
          : "Coins Deposited Successfully",
        {
          type: "success",
        }
      );
      setIsLoading(false);
      navigate("/sitewise-report");
    } else if (response) {
      toast(response.data.message, {
        type: "error",
      });
      setIsLoading(false);
    } else {
      toast(message, {
        type: "error",
      });
      setIsLoading(false);
    }
  };
  return (
    <div className="w-[100%]">
      <TopNavigation
        title={isWithdraw ? "Withdraw" : "Deposit"}
        isBackNav={true}
      />
      <div className="w-[100%] flex-col px-2 py-5 justify-center items-center flex ">
        <img
          className="mt-10 w-[80px] h-[80px] rounded-full"
          src={exchangeIconUrl}
        />
        <h2 className="text-3xl mt-2 text-gray-200">{exchangeName}</h2>
        <p className="mt-2 text-gray-300 text-xl">{exchangeUrl}</p>
        <p className="mt-2 text-gray-300 text-xl">{userNameExchange}</p>
        <div className="w-[100%] px-20 mt-5 flex flex-col">
          <span className="text-gray-300 text-sm mb-1">Coins*</span>
          <input
            onChange={(e) => setCoins(e.target.value)}
            value={coins}
            className="p-2 text-white outline-none rounded-md bg-[#1A1D20]"
            placeholder="Preferred username"
          />
        </div>
        <button
          onClick={handleSubmit}
          className="blue_button rounded-full text-gray-300 p-3 mt-8"
        >
          {isWithdraw ? `Continue to Withdraw` : `Continue to Pay ${coins}`}
        </button>
      </div>
    </div>
  );
}
