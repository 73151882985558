import { Switch } from "@material-tailwind/react";
import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TopNavigation from "../../../components/TopNavigation";
import Table from "../../../components/UX/Table/Table";
import {
  getPaymentModesAPI,
  updatePaymentDetailsAPI,
} from "../../../service/UserService";

export default function BankList() {
  const [paymentModes, setPaymentModes] = useState([]);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const navigate = useNavigate();
  const getBankDetails = async () => {
    const {
      data: { PaymentDetails },
    } = await getPaymentModesAPI();
    setPaymentModes(PaymentDetails);
  };
  useEffect(() => {
    getBankDetails();
  }, []);
  const updateBankDetails = async (payload) => {
    navigate("/bank-details/" + payload.paymentDetailsId, {
      state: payload,
    });
    /*  const { data, statusCode, message } = await updatePaymentDetailsAPI(
      payload.paymentDetailsId,
      payload
    );
    if (statusCode === 200) {
      toast("Bank details updated successfully", {
        type: "success",
      });
      await getBankDetails();
    } else {
      toast(message, {
        type: "error",
      });
      await getBankDetails();
    } */
  };

  useEffect(() => {
    setSkipPageReset(false);
  }, [paymentModes]);
  const updateMyData = (rowIndex, columnId, value) =>
    setPaymentModes((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
            isEdited: true,
          };
        }
        return row;
      })
    );

  const handleQrImageChange = async (row, inputRef) => {
    //inputRef.current.click()
    console.log(inputRef.current.value);
    /*  updateMyData(row.row.index,qrCodeImageUrl,); */
  };
  return (
    <>
      <TopNavigation isBackNav={true} title="Bank Details" />
      <div className="container  flex flex-row w-[100%] align-middle justify-end">
        <Link to={"/addNewBank"}>
          <div className="px-3 cursor-pointer py-1 bg-[#FFBF44] my-3 text-white rounded-md">
            Add New
          </div>
        </Link>
      </div>
      <div class="h-auto w-[100%] h- relative">
        <Table
          data={paymentModes}
          pageLimit={50}
          showPagination={true}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
          columns={[
            {
              Header: "Bank Name",
              accessor: "bankName",
            },
            {
              Header: "Qr Image",
              accessor: "qrCodeImageUrl",
              Cell: (row) => {
                const inputRef = useRef();
                return (
                  <>
                    <input type="file" ref={inputRef} hidden={true} />
                    <img src={row.data[row.row.index].qrCodeImageUrl} />
                  </>
                );
              },
            },
            {
              Header: "Account Number",
              accessor: "accountNumber",
            },
            {
              Header: "IFSC Code",
              accessor: "ifscCode",
            },
            {
              Header: "Account Holder Name",
              accessor: "accountHolderName",
            },
            {
              Header: "UPI ID",
              accessor: "upiId",
            },
            {
              Header: "Mobile Number",
              accessor: "mobile",
            },
            {
              Header: "Status",
              accessor: "isMethodActive",
              Cell: (row) => (
                <Switch
                  color="blue"
                  size="regular"
                  checked={row.data[row.row.id].isMethodActive}
                  id={row.row.id}
                />
              ),
            },

            {
              Header: "Action",
              accessor: "action",
              Cell: (row) => (
                <div className="flex flex-row">
                  <div
                    onClick={() => updateBankDetails(row.data[row.row.id])}
                    className="px-3 cursor-pointer py-1 bg-[#10A2F8] text-white rounded-md"
                  >
                    Update
                  </div>
                </div>
              ),
            },
          ]}
        />
      </div>
    </>
  );
}
