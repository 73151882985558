import React from "react";

const NoData = ({ title }) => {
  return (
    <div className="empty_page">
      <img src={require("../../assets/img/asset1.png")} />
      <p className="text-[#697483] mt-5 text-lg">
        You don’t have any {title} yet.
      </p>
    </div>
  );
};

export default NoData;
