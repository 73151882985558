import React from "react";
import { useState } from "react";
import { useRef } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addNewBannerAPI, uploadFileAPI } from "../service/UserService";
import TopNavigation from "./TopNavigation";

export default function AddNewBanner() {
  const uploadFileRef = useRef();
  const [banner, setBanner] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const navigate = useNavigate();
  const handleAddBanner = async () => {
    if (banner) {
      const { data, statusCode, message } = await uploadFileAPI(banner[0]);
      if (statusCode === 200) {
        const uri = data["File Url"].CREATED;
        const { statusCode, message } = await addNewBannerAPI({
          bannerUrl: uri,
          fromDate: new Date(fromDate),
          toDate: new Date(toDate),
          isActive: true,
          bannerPriority: 0,
        });
        if (statusCode === 200) {
          toast("Banner added successfully", {
            type: "success",
          });
          navigate("/manage-banners");
        } else {
          toast(message, {
            type: "error",
          });
        }
      } else {
        toast(message, {
          type: "error",
        });
        return;
      }
    } else {
      toast("Please select a file", {
        type: "error",
      });
    }
  };
  return (
    <>
      <TopNavigation
        isBackNav={true}
        title="Add new banner"
        showNotification={true}
      />
      <div className="h-[91.5vh]">
        <input
          type="file"
          onChange={(e) => {
            setBanner(e.target.files);
          }}
          ref={uploadFileRef}
          className="hidden"
        />
        {banner ? (
          <img
            onClick={() => uploadFileRef.current.click()}
            className="max-h-[30vh] my-5 w-[100%]"
            src={URL.createObjectURL(banner[0])}
          />
        ) : (
          <div
            onClick={() => uploadFileRef.current.click()}
            className="cursor-pointer my-5  w-[100%] rounded-lg px-5 py-10 text-center border-4 border-dashed border-gray-500"
          >
            <p className="text-lg text-gray-500 font-bold">
              UPLOAD BANNER HERE*
            </p>
          </div>
        )}
        <div class="flex items-center justify-center">
          <div class="datepicker relative form-floating mb-3 xl:w-96">
            <label for="floatingInput" class="text-gray-700">
              Valid from date
            </label>
            <input
              onChange={(e) => setFromDate(e.target.value)}
              type="date"
              class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              placeholder="Select a date"
            />
          </div>
        </div>
        <div class="flex items-center justify-center">
          <div class="datepicker relative form-floating mb-3 xl:w-96">
            <label for="floatingInput" class="text-gray-700">
              Valid Upto date
            </label>
            <input
              onChange={(e) => setToDate(e.target.value)}
              type="date"
              class="form-control block w-full px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
              placeholder="Select a date"
            />
          </div>
        </div>
        <div className="w-[100%] flex justify-center align-middle">
          <button
            onClick={handleAddBanner}
            className="w-[30%] py-2 my-4 bg-[#FFBF44] rounded-md self-center"
          >
            Add Banner
          </button>
        </div>
      </div>
    </>
  );
}
