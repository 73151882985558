import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import TopNavigation from "../../components/TopNavigation";
import Table from "../../components/UX/Table/Table";
import { LoadingContext } from "../../context/LoadingContext";
import { getUsersAPI, searchUserAPI } from "../../service/UserService";

export default function MyUsers() {
  const [myUsers, setMyUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageLimit, setPageLimit] = useState(20);
  const { setLoading } = useContext(LoadingContext);
  const navigate = useNavigate();
  const getUsers = async () => {
    setLoading(true);
    const {
      data: {
        data: { content, size, totalPages, numberOfElements },
      },
    } = await getUsersAPI(pageLimit, currentPage);
    setTableData({ size, totalPages, numberOfElements });

    let sortedTransactions = content.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setLoading(false);
    setMyUsers(sortedTransactions);
  };
  const [tableData, setTableData] = useState({
    size: 0,
  });
  const nextPage = () => {
    if (currentPage < tableData.totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage >= 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  useEffect(() => {
    getUsers();
  }, [currentPage, pageLimit]);
  const [columns, setColumns] = useState([
    {
      Header: "User Id",
      accessor: "userId",
    },
    {
      Header: "User Name",
      accessor: "userName",
    },
    {
      Header: "Mobile Number",
      accessor: "mobileNumber",
    },
    {
      Header: "Wallet Balance",
      accessor: "walletBalance",
    },
    {
      Header: "Withdrawn From Wallet",
      accessor: "withdrawnFromWallet",
    },
    {
      Header: "Deposit From Wallet",
      accessor: "depositedInWallet",
    },
    {
      Header: "PAL",
      accessor: "pal",
    },
    {
      Header: "Referred",
      accessor: "referred",
    },
    {
      Header: "Accounts Closed",
      accessor: "accountsClosed",
    },
    {
      Header: "Joined At",
      accessor: "joinedAt",
      Cell: (row) => (
        <span className="text-gray-400 text-sm">
          {row.value ? new Date(row.value).toDateString() : "Not Validated"}
        </span>
      ),
    },
  ]);
  const searchUser = async (e) => {
    // if (e.target.value.length < 5) {
    //   return;
    // }
    const {
      data: { data },
    } = await searchUserAPI(e.target.value);
    setMyUsers(data);
  };

  return (
    <>
      <TopNavigation isBackNav={true} title="My Users" />
      <div className="w-[100%] flex flex-col items-center justify-center">
        <div className="flex my-2 w-[100%] flex-row rounded-lg p-2 bg-[#1A1D20] items-center">
          <img
            className="h-[20px] w-[20px]"
            src={require("../../assets/img/search_icon.png")}
          />
          <input
            onChange={(e) => {
              searchUser(e);
            }}
            className="bg-transparent text-white w-[100%] outline-none ml-2 "
            placeholder="Name,Phone"
          />
        </div>
        <div className="  w-[98%] ">
          <Table
            size={tableData.size}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            data={myUsers}
            columns={columns}
            totalPages={tableData.totalPages}
            nextPage={nextPage}
            prevPage={prevPage}
            showPagination={true}
          />
        </div>
      </div>
    </>
  );
}
