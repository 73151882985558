import React, { useCallback, useContext, useEffect, useState } from "react";
import Collapsible from "react-collapsible";
import TopNavigation from "../../components/TopNavigation";
import NoData from "../../components/UX/NoData";
import { NotificationContext } from "../../context/NotificationContext";
import { useNavigate } from "react-router-dom";
export default function Notification() {
  const [notifications, setNotifications] = useContext(NotificationContext);
  const navigate = useNavigate();
  const handleViewDetails = (notification) => {
    navigate(`/payout-screenshots/${notification.transactionId}`);
  };
  return (
    <div className="w-[100%]">
      <TopNavigation isBackNav={true} title="Notifications" />
      <label
        className="block uppercase tracking-wide text-gray-300 text-sm font-bold mt-5"
        htmlFor="grid-first-name"
      >
        Today's Notifications
      </label>
      <div className=" container p-5  ">
        {!notifications && <NoData title={"Notifications"} />}
        {notifications.map(
          (
            {
              approvedCoins,
              coins,
              createdAt,
              description,
              notificationType,
              title,
              transactionType,
              transactionId,
            },
            index
          ) => (
            <div className=" cursor-pointer bg-[#212528] relative rounded-xl flex flex-col px-5 py-8 mt-5">
              <Collapsible
                trigger={
                  <div className="   relative  justify-between flex flex-row items-center">
                    <div className="flex flex-row items-center">
                      <img
                        className="button_svg"
                        src={
                          notificationType == "DEPOSIT" ||
                          notificationType == "WITHDRAW"
                            ? require("../../assets/img/yellow_bank_icon.png")
                            : require("../../assets/img/next_icon_blue.png")
                        }
                      />
                      <p className="text-white ml-10  text-lg">{title}</p>
                    </div>
                    <img
                      className="w-4"
                      src={require("../../assets/img/down_caret_icon.png")}
                    />
                  </div>
                }
              >
                <div className=" mt-5 rounded-lg  pl-5 pr-2 py-2 text-gray-200">
                  <p>{description}</p>
                </div>
                <div className="bg-[#1b1e20] mt-5 rounded-lg  pl-5 pr-2 py-2 text-gray-400">
                  <div className="flex flex-row justify-between items-center">
                    <p>Details</p>
                    <span
                      onClick={() =>
                        handleViewDetails({
                          approvedCoins,
                          coins,
                          createdAt,
                          description,
                          notificationType,
                          title,
                          transactionType,
                          transactionId,
                        })
                      }
                      className="bg-gray-700 px-2 text-sm rounded-md py-1 text-gray-200"
                    >
                      View Details
                    </span>
                  </div>
                  <div className="mt-5">
                    <div className="flex flex-row justify-between items-center">
                      <p className="text-gray-400">Coins</p>
                      <p>{coins}</p>
                    </div>
                  </div>
                </div>
                <div className="absolute bg-[#FFBF44] rounded top-0 right-0">
                  <p className="text-white text-xs px-2 py-1">
                    {new Date(createdAt).toDateString()}
                  </p>
                </div>
              </Collapsible>
            </div>
          )
        )}
      </div>
    </div>
  );
}
