import React, { useContext, useEffect, useState } from "react";
import { Routes, Route, redirect } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { appRoutes } from "./appRoutes";

const NavigationRoutes = () => {
  const [roleBasedNavigations, setRoleBasedNavigations] = useState(appRoutes);
  const { user, isLoggedIn } = useContext(UserContext);
  const mapRoles = () => {
    let userRoles = user.User.roles;
    let roleBasedNavigationsCopy = [...roleBasedNavigations];
    roleBasedNavigationsCopy.forEach((nav) => {
      if (nav.roles.includes("all")) {
        nav.hasRole = true;
      } else {
        nav.roles.forEach((role) => {
          if (userRoles.map((r) => r.roleName).includes(role)) {
            nav.hasRole = true;
          }
        });
      }
    });
    setRoleBasedNavigations(roleBasedNavigationsCopy);
  };
  useEffect(() => {
    if (isLoggedIn) {
      mapRoles();
    }
  }, [user]);
  return (
    <Routes>
      {isLoggedIn
        ? appRoutes.map(
            ({ path, element, isAuthPage, ...rest }) =>
              !isAuthPage && <Route path={path} element={element} />
          )
        : appRoutes.map(
            ({ path, element, isAuthPage, ...rest }) =>
              isAuthPage && <Route path={path} element={element} />
          )}

      <Route path="*" element={<p>Page not found</p>} />
    </Routes>
  );
};

export default NavigationRoutes;
