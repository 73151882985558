import { BrowserRouter } from "react-router-dom";
import Wrapper from "./components/Wrapper";
import { DrawerContext } from "./context/DrawerContext";
import { NotificationContext } from "./context/NotificationContext";
import { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UserProvider } from "./context/UserContext";
import { SocketContext } from "./context/SocketContext";
import { WEBSOCKET_URI } from "./utils/config";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { getTokenFromLocalStorage } from "./service/UserService";
import NavigationRoutes from "./routes/NavigationRoute";
import SideBar from "./components/SideBar";
import { LoadingContext } from "./context/LoadingContext";
import Loading from "./components/Loading";
export default function App() {
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const { sendJsonMessage, getWebSocket } = useWebSocket(
    WEBSOCKET_URI + `?token=${getTokenFromLocalStorage()}`,
    {
      onOpen: () => {
        sendJsonMessage({
          event: "get-notifications",
        });
      },
      onClose: () => console.log("WebSocket connection closed."),
      shouldReconnect: (closeEvent) => true,
      onMessage: (event) => {
        const { data } = JSON.parse(event.data);
        setNotifications(
          data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
        );
      },
    }
  );
  const [notifications, setNotifications] = useState([]);
  return (
    <BrowserRouter basename="/">
      <ToastContainer />
      <UserProvider>
        <NotificationContext.Provider value={[notifications, setNotifications]}>
          <DrawerContext.Provider value={[isSideBarOpen, setIsSideBarOpen]}>
            <LoadingContext.Provider value={{ isLoading, setLoading }}>
              <Wrapper>
                {isSideBarOpen ? <SideBar /> : null}
                {isLoading ? <Loading /> : null}
                <NavigationRoutes />
              </Wrapper>
            </LoadingContext.Provider>
          </DrawerContext.Provider>
        </NotificationContext.Provider>
      </UserProvider>
    </BrowserRouter>
  );
}
