import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import TopNavigation from "../../../components/TopNavigation";
import {
  getSignedUrlAPI,
  getUserIdDetailsAPI,
} from "../../../service/UserService";

export default function IdDetails(router) {
  const location = useLocation();
  const {
    state: {
      exchangeData: {
        exchangeIconUrl,
        exchangeUrl,
        userNameExchange,
        id,
        userId,
        userIdExchange,
        exchangeName,
        password,
      },
    },
  } = location;
  const [transactions, setTransactions] = useState([]);
  const [createdAt, setCreatedAt] = useState("");
  const [balance, setBalance] = useState(0);
  const getPassbook = async () => {
    const { data } = await getUserIdDetailsAPI(id);
    var date = new Date(0);
    date.setUTCSeconds(data.createdAt);
    setCreatedAt(date);
    let transactions_ = data["User Transactions"];
    for (let index = 0; index < transactions_.length; index++) {
      let exchangeIconUrl = transactions_[index]["exchangeIconUrl"];
      if (exchangeIconUrl) {
        const {
          data: { url },
        } = await getSignedUrlAPI(exchangeIconUrl);
        transactions_[index]["exchangeIconUrl"] = url;
      }
    }
    transactions_.reverse();
    setTransactions(transactions_);
  };
  useEffect(() => {
    getPassbook();
  }, []);
  const copyToClipboard = (url) => {
    const el = document.createElement("textarea");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    toast("Copied to clipboard", { type: "success" });
  };
  return (
    <div className="w-[100%] flex flex-col items-center">
      <TopNavigation
        title="ID Details"
        isBackNav={true}
        showNotification={true}
      />
      <div className="flex row w-[100%] p-5">
        <img className="w-[20%] rounded-full mr-5" src={exchangeIconUrl} />
        <div className="flex text-gray-300 flex-col w-[70%]">
          <a href={exchangeUrl}>
            <span className="flex flex-row text-lg">
              {exchangeUrl}{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-4 h-4 ml-1 mt-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25"
                />
              </svg>
            </span>
          </a>
          <span className="flex flex-row text-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
              />
            </svg>
            {exchangeName}
          </span>
          <span className="flex flex-row text-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              />
            </svg>
            Username : {userNameExchange}{" "}
            {/*  <img
              onClick={() => copyToClipboard(userNameExchange)}
              className="h-[20px] w-[15px] ml-2 cursor-pointer"
              src={require("../../assets/img/copy_icon.png")}
            /> */}
          </span>
          <span className="flex flex-row text-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
              />
            </svg>
            Password : {password}
            {/*    <img
              onClick={() => copyToClipboard(password)}
              className="h-[20px] w-[15px] ml-2 cursor-pointer"
              src={require("../../assets/img/copy_icon.png")}
            /> */}
          </span>
          <span className="flex flex-row text-center items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 mr-1"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5"
              />
            </svg>
            Created At: {new Date(createdAt).toLocaleDateString()}
          </span>
        </div>
      </div>
      <div className="w-[95%] text-gray-300 p-2 rounded-lg bg-[#1B1E21] ">
        <div className="text-md font-bold border-b-[1px] w-[100%] mb-2 border-gray-600 ">
          ID Balance Details
        </div>
        <div className="flex px-2 flex-row w-[100%] justify-between">
          <span>Available balance</span>
          {balance}
        </div>
      </div>
      <div className="w-[100%] px-5 mt-5 text-lg ">
        <span className="text-gray-300 border-b-[2px] mb-5 border-blue-500">
          Transaction History
        </span>
        {transactions.map(
          ({
            account,
            approvedCoins,
            coins,
            exchangeUrl,
            fromUserId,
            paymentProofs,
            paymentType,
            requestGenerated,
            requestValidated,
            toUserId,
            transactionId,
            transactionStatus,
            transactionType,
            userLoginNameExchange,
            validatedBy,
            exchangeIconUrl,
          }) => (
            <div className="flex self-center flex-row px-2 py-4  items-center justify-between align-middle mt-1 bg-[#212528]  w-[100%]">
              <div className="w-[100%] flex flex-row align-middle items-center">
                {/*  <img
                  className="w-14 mr-5"
                  src={
                    exchangeIconUrl
                      ? exchangeIconUrl
                      : require("../../assets/img/full_icon.png")
                  }
                /> */}
                <div className="flex flex-col">
                  <span className="text-gray-300 text-sm">
                    {transactionType.charAt(0) +
                      transactionType
                        .slice(1)
                        .toLowerCase()
                        .replaceAll("_", " ")}
                  </span>
                  <span className="text-gray-300 text-sm">{exchangeUrl}</span>
                  <span className="text-[#FFFFFF61] text-sm">
                    {new Date(requestGenerated).toDateString()}
                  </span>
                </div>
              </div>
              <div className="flex flex-col text-right px-2">
                <p className="text-gray-200">
                  {transactionType == "WITHDRAW_FROM_WALLET" ||
                  transactionType == "DEPOSIT_TO_ID" ||
                  transactionType == "CREATE_ID"
                    ? "-"
                    : "+"}
                  {approvedCoins}
                </p>
                <p
                  className={
                    transactionStatus == "PENDING"
                      ? "text-yellow-500"
                      : transactionStatus == "REJECTED"
                      ? "text-red-400"
                      : "text-green-400"
                  }
                >
                  {transactionStatus.toUpperCase()}
                </p>
              </div>
            </div>
          )
        )}
      </div>
    </div>
  );
}
