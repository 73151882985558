import React, { useContext, useEffect } from "react";
import { useState } from "react";
import TopNavigation from "../../components/TopNavigation";
import {
  approveRejectTransactionAPI,
  getPaymentDetailsAPI,
  getSignedUrlAPI,
  getTransactionsAPI,
  getTransactionsByIdAPI,
} from "../../service/UserService";
import CustomModal from "../../components/CustomModal";
import { toast } from "react-toastify";
import Table from "../../components/UX/Table/Table";
import { LoadingContext } from "../../context/LoadingContext";
import { useLocation } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
export default function PayoutScreenShots(router) {
  const location = useLocation();
  var id = undefined;
  if (location.state || location.pathname.split("/")[2]) {
    if (location.state) {
      id = location.state.notification.transactionId;
    }
    if (location.pathname.split("/")[2]) {
      id = location.pathname.split("/")[2];
    }
  }
  const { user } = useContext(UserContext);
  const [pageLimit, setPageLimit] = useState(20);
  const [transactions, setTransactions] = useState([]);
  const { setLoading } = useContext(LoadingContext);
  const [filters, setFilters] = useState([
    {
      name: "All",
      event: null,
      selected: true,
    },
    {
      name: "Deposit",
      event: "DEPOSIT_TO_WALLET",
      selected: false,
    },
    {
      name: "Withdrawal",
      event: "WITHDRAW_FROM_WALLET",
      selected: false,
    },
    {
      name: "Deposit to ID",
      event: "DEPOSIT_TO_ID",
      selected: false,
    },
    {
      name: "Withdrawal from ID",
      event: "WITHDRAW_FROM_ID",
      selected: false,
    },
    {
      name: "Create ID",
      event: "CREATE_ID",
      selected: false,
    },
    {
      name: "Other",
      event: "OTHER",
      selected: false,
    },
  ]);
  const [selectedPayment, setSelectedPayment] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [actualCoins, setActualCoins] = useState(0);
  const [tableData, setTableData] = useState({
    size: 0,
  });
  const loadTransactions = async () => {
    setLoading(true);
    const {
      data: {
        data: { content, size, totalPages, numberOfElements },
      },
    } = await getTransactionsAPI(
      pageLimit,
      currentPage,
      filters
        .filter((filter) => filter.selected && filter.event)
        .map((filter) => filter.event)
    );
    setTableData({ size, totalPages, numberOfElements });
    for (let i = 0; i < content.length; i++) {
      if (content[i].paymentProofs && content[i].paymentProofs != null) {
        try {
          const {
            data: { url },
          } = await getSignedUrlAPI(content[i].paymentProofs);
          content[i].paymentProofs = url ? url : "";
        } catch (e) {}
      }
    }
    let sortedTransactions = content.sort((a, b) => {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
    setLoading(false);
    setTransactions(sortedTransactions);
  };
  const loadFilters = (roles) => {
    let isAdmin = false;
    for (let i = 0; i < roles.length; i++) {
      if (roles[i].roleName === "ROLE_ADMIN") {
        isAdmin = true;
        break;
      }
    }
    if (isAdmin) {
      setFilters([
        {
          name: "All",
          event: null,
          selected: true,
        },
        {
          name: "Deposit",
          event: "DEPOSIT_TO_WALLET",
          selected: false,
        },
        {
          name: "Withdrawal",
          event: "WITHDRAW_FROM_WALLET",
          selected: false,
        },
        {
          name: "Deposit to ID",
          event: "DEPOSIT_TO_ID",
          selected: false,
        },
        {
          name: "Withdrawal from ID",
          event: "WITHDRAW_FROM_ID",
          selected: false,
        },
        {
          name: "Create ID",
          event: "CREATE_ID",
          selected: false,
        },
        {
          name: "Other",
          event: "OTHER",
          selected: false,
        },
      ]);
    } else {
      setFilters([]);
    }
  };
  useEffect(() => {
    loadTransactions();
    loadFilters(user.User.roles);
  }, [currentPage, pageLimit]);
  useEffect(() => {
    loadTransactions().then(() => {
      if (id != undefined) {
        getTransactionsById(id);
      }
    });
  }, [filters, id]);
  const getTransactionsById = async (id) => {
    const {
      data: { userTransaction },
    } = await getTransactionsByIdAPI(id);
    let userTransactionCopy = userTransaction;
    try {
      const {
        data: { url },
      } = await getSignedUrlAPI(userTransactionCopy.paymentProofs);
      userTransactionCopy.paymentProofs = url ? url : "";
    } catch (e) {}
    setActualCoins(userTransaction.coins);
    setSelectedPayment(userTransactionCopy);
    setIsModalOpen(true);
  };

  const handleFilterChange = (index) => {
    let newFilters = [...filters];
    newFilters[index].selected = !newFilters[index].selected;
    setFilters(newFilters);
  };

  const handlePaymentApproval = async (STATUS) => {
    let payload = {
      transactionId: selectedPayment.transactionId,
      transactionStatus: STATUS,
      actualCoins,
      deposit: selectedPayment.transactionType != "WITHDRAW_FROM_WALLET",
      transactionType: selectedPayment.transactionType,
    };
    const { data, statusCode, message } = await approveRejectTransactionAPI(
      payload
    );
    if (statusCode == 200) {
      setIsModalOpen(false);
      setSelectedPayment(false);
      toast(message, {
        type: "success",
      });
    } else {
      toast(message, {
        type: "error",
      });
    }
  };
  const handleModalToggle = async (selectedPayment_) => {
    const { data } = await getPaymentDetailsAPI(
      selectedPayment_.fromUserId
        ? selectedPayment_.fromUserId
        : selectedPayment_.toUserId
    );

    selectedPayment_.paymentDetails = data;
    setSelectedPayment(selectedPayment_);
    setIsModalOpen(true);
    setActualCoins(selectedPayment_.coins);
  };
  const nextPage = () => {
    if (currentPage < tableData.totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const [columns, setColumns] = useState([
    {
      Header: "User Id",
      accessor: "toUserId",
    },
    {
      Header: "Coins",
      accessor: "coins",
    },
    {
      Header: "Transaction Status",
      accessor: "transactionStatus",
    },
    {
      Header: "Transaction Type",
      accessor: "transactionType",
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: (row) => (
        <button
          onClick={() => handleModalToggle(row.data[row.row.index])}
          className="px-[5px] cursor-pointer py-[3px] text-white bg-green-400 text-sm rounded-md"
        >
          Show Screenshots
        </button>
      ),
    },
  ]);
  const [bankDetailsColumns, setBankDetailsColumns] = useState({
    bankDetailsColumn: [
      {
        Header: "Bank Name",
        accessor: "bankName",
      },
      {
        Header: "Account Number",
        accessor: "accountNumber",
      },
    ],
    upiDetailsColumn: [
      {
        Header: "Name",
        accessor: "userName",
      },
      {
        Header: "id",
        accessor: "value",
      },
    ],
  });
  const normalizeData = (data) => {
    let upiDetails = [];
    let bankDetails = [];
    for (let key in data) {
      if (key == "UserUPIDetails") {
        for (let key1 in data[key]) {
          for (let i = 0; i < data[key][key1].length; i++) {
            upiDetails.push({
              ...data[key][key1][i],
              upiType: key1,
            });
          }
        }
      } else {
        bankDetails = data[key];
      }
    }
    return { upiDetails, bankDetails };
  };
  return (
    <>
      <TopNavigation isBackNav={true} title="Payoutscreen shots" />
      <div className="flex flex-col   w-[100%] text-white text-center px-1">
        {isModalOpen && (
          <CustomModal isOpen={isModalOpen} onClose={setIsModalOpen}>
            <div className="flex flex-col  items-center jutify-center">
              <p className="text-gray-300 text-lg">Manage Payment</p>
              {selectedPayment.transactionType == "WITHDRAW_FROM_WALLET" ||
              selectedPayment.transactionType == "CREATE_ID" ||
              selectedPayment.transactionType == "DEPOSIT_TO_ID" ? (
                <div className=" w-[100%] ">
                  {selectedPayment.paymentDetails &&
                    Object.keys(selectedPayment.paymentDetails).map((key) => {
                      return (
                        <div className="flex flex-col w-[100%]  items-center justify-center">
                          <p className="text-gray-300 my-2 text-lg">
                            {key == "UserBankDetails"
                              ? "Bank Details"
                              : key == "UserUPIDetails"
                              ? "UPI Details"
                              : ""}
                          </p>
                          <div className="flex flex-col w-[100%]  items-center justify-center">
                            <Table
                              columns={
                                key == "UserBankDetails"
                                  ? bankDetailsColumns.bankDetailsColumn
                                  : key == "UserUPIDetails"
                                  ? bankDetailsColumns.upiDetailsColumn
                                  : []
                              }
                              data={
                                key == "UserBankDetails"
                                  ? normalizeData(
                                      selectedPayment.paymentDetails
                                    ).bankDetails
                                  : normalizeData(
                                      selectedPayment.paymentDetails
                                    ).upiDetails
                              }
                              tableData={
                                normalizeData(selectedPayment.paymentDetails)
                                  .upiDetails
                              }
                              showPagination={false}
                              prevPage={prevPage}
                              nextPage={nextPage}
                              currentPage={0}
                              pageLimit={10}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              ) : (
                <img
                  className="rounded-lg mt-2 h-[50vh]"
                  src={selectedPayment.paymentProofs}
                />
              )}

              <div className="flex mt-2 flex-wrap w-[100%] justify-center items-center">
                <span className="px-2 m-2 py-1 bg-slate-600 text-gray-300 rounded-lg">
                  TRANSACTION ID : {selectedPayment.transactionId}
                </span>
                <span className="px-2 m-2 py-1 bg-slate-600 text-gray-300 rounded-lg">
                  USER ID : {selectedPayment.toUserId}
                </span>
                <span className="px-2 m-2 py-1 bg-slate-600 text-gray-300 rounded-lg">
                  TRANSACTION STATUS : {selectedPayment.transactionStatus}
                </span>
                {selectedPayment.transactionType == "WITHDRAW_FROM_WALLET" && (
                  <>
                    <span className="px-2 m-2 py-1 bg-slate-600 text-gray-300 rounded-lg">
                      PAYMENT TYPE :{" "}
                      {selectedPayment.paymentType.replace("_", "-")}
                    </span>
                    <span className="px-2 m-2 py-1 bg-slate-600 text-gray-300 rounded-lg">
                      TRANSACTION TYPE :{" "}
                      {selectedPayment.transactionType.replaceAll("_", "-")}
                    </span>
                  </>
                )}
              </div>
              {selectedPayment.transactionStatus == "PENDING" && (
                <>
                  <input
                    type="number"
                    min={0}
                    value={actualCoins}
                    onChange={(e) => setActualCoins(e.target.value)}
                    className="px-2 py-1 outline-none bg-gray-800 text-gray-300 rounded-lg mt-2"
                  />
                  <div className="flex flex-row mt-2">
                    <span
                      onClick={() => handlePaymentApproval("APPROVED")}
                      className="bg-green-500 cursor-pointer text-white px-2 py-1 rounded-lg "
                    >
                      Approve
                    </span>
                    <span
                      onClick={() => handlePaymentApproval("REJECTED")}
                      className="bg-red-500 cursor-pointer text-white px-2 py-1 rounded-lg  ml-2"
                    >
                      Reject
                    </span>
                  </div>
                </>
              )}
            </div>
          </CustomModal>
        )}
        <div className="flex flex-wrap my-2">
          {filters.map(({ name, event, selected }, index) => (
            <div
              onClick={() => handleFilterChange(index)}
              className={`${
                selected ? "bg-[#FFBF44]" : ""
              } cursor-pointer px-2 py-1 m-1 border-[1px] border-gray-500 rounded-md text-sm`}
            >
              {name}
            </div>
          ))}
        </div>
        <div className="h-auto overflow-y-auto px-1 overflow-x-hidden w-[100%] ">
          <Table
            size={tableData.size}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            pageLimit={pageLimit}
            setPageLimit={setPageLimit}
            data={transactions}
            columns={columns}
            totalPages={tableData.totalPages}
            nextPage={nextPage}
            prevPage={prevPage}
            showPagination={true}
          />
        </div>
      </div>
    </>
  );
}
