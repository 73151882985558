import { Option, Select } from "@material-tailwind/react";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import CustomModal from "../../../components/CustomModal";
import TopNavigation from "../../../components/TopNavigation";
import Table from "../../../components/UX/Table/Table";
import { LoadingContext } from "../../../context/LoadingContext";
import {
  getAdminHandlerAPI,
  getAdminUsersAPI,
  putAdminHandlerAPI,
} from "../../../service/UserService";

export default function UserToAdminManage() {
  const history = useNavigate();
  const [adminUsers, setAdminUsers] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [pageLimit, setPageLimit] = useState(20);
  const [selectedAdmin, setSelectedAdmin] = useState();
  const [admins, setAdmins] = useState([]);
  const [searchBy, setSearchBy] = useState("mobileNumber");
  const { setLoading } = useContext(LoadingContext);
  const [searchValue, setSearchValue] = useState("");
  const handleSelectedUsers = (adminUsers_, uid) => {
    adminUsers_.filter((admin) => admin.uid === uid)[0].selected =
      !adminUsers_.filter((admin) => admin.uid === uid)[0].selected;
    setAdminUsers(adminUsers_);
  };
  const [columns, setColumns] = useState([
    {
      Header: "Select",
      accessor: "selected",
      Cell: (row) => {
        return (
          <input
            type="checkbox"
            checked={row.data[row.row.id].selected}
            onChange={() =>
              handleSelectedUsers(row.data, row.data[row.row.id].uid)
            }
          />
        );
      },
    },

    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Mobile Number",
      accessor: "mobileNumber",
    },
    {
      Header: "Assigned Admin",
      accessor: "adminUserName",
    },
  ]);
  const [currentPage, setCurrentPage] = useState(0);
  const [tableData, setTableData] = useState({
    size: 0,
  });
  const nextPage = () => {
    if (currentPage < tableData.totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };
  const prevPage = () => {
    if (currentPage >= 0) {
      setCurrentPage(currentPage - 1);
    }
  };
  const getAdminUsers = async (adminUserName, mobileNumber) => {
    setLoading(true);
    const {
      data: {
        users: { content },
      },
    } = await getAdminHandlerAPI(
      pageLimit,
      currentPage,
      adminUserName,
      mobileNumber
    );
    setAdminUsers(content);
    setLoading(false);
  };
  const getAdmins = async () => {
    const { data } = await getAdminUsersAPI();
    setAdmins(data.users);
  };
  const handleAssignAdmin = async () => {
    setShowModal(true);
  };
  useEffect(() => {
    getAdmins();
    getAdminUsers();
  }, []);
  const assignAdmin = async () => {
    setLoading(true);
    const { data, message, statusCode } = await putAdminHandlerAPI(
      selectedAdmin,
      adminUsers.filter((user) => user.selected).map((user) => user.uid)
    );
    if (statusCode === 200) {
      toast(message, { type: "success" });
      getAdminUsers();
      setShowModal(false);
    } else {
      toast(message, { type: "error" });
    }
    setLoading(false);
  };
  const handleSearch = async () => {
    if (searchBy === "mobileNumber") {
      await getAdminUsers(null, searchValue);
    } else {
      await getAdminUsers(searchValue, null);
    }
  };
  return (
    <>
      <CustomModal
        onClose={() => {
          setShowModal(false);
        }}
        isOpen={showModal}
      >
        <div className="flex mt-5 flex-col items-center justify-center w-full h-full">
          <Select
            color="lightBlue"
            size="regular"
            outline={true}
            value={selectedAdmin}
            onChange={(e) => {
              setSelectedAdmin(e);
            }}
            defaultValue={null}
            placeholder="Select Admin to Assign"
          >
            {admins.map((admin) => (
              <Option value={admin.name}>{admin.name}</Option>
            ))}
          </Select>
          <button
            className="bg-blue-500 mt-5 text-white px-4 py-2 rounded-md"
            onClick={() => assignAdmin()}
          >
            Assign
          </button>
        </div>
      </CustomModal>
      <TopNavigation title="Admin Management" isBackNav={true} />
      <div className="flex flex-row">
        <div className="flex my-2 w-[90%] mr-2 flex-row rounded-lg p-2 bg-[#1A1D20] items-center">
          <img
            className="h-[20px] w-[20px]"
            src={require("../../../assets/img/search_icon.png")}
          />
          <input
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            className="bg-transparent w-[77%] text-white  outline-none ml-2 "
            placeholder="ID,Name,Phone"
          />
        </div>
        <button onClick={() => handleSearch()}>
          <span className="text-white bg-blue-500 rounded-[5px] px-2 py-1 text-sm">
            SEARCH
          </span>
        </button>
      </div>
      <div className="w-[100%] flex flex-row justify-end">
        <label
          className="block uppercase tracking-wide text-gray-500 text-xs font-bold mr-2"
          htmlFor="grid-first-name"
        >
          Search by
        </label>
        <div className="flex flex-row ">
          <div
            onClick={
              searchBy === "mobileNumber"
                ? () => {
                    setSearchBy("adminUserName");
                  }
                : () => {
                    setSearchBy("mobileNumber");
                  }
            }
            className={`bg-${
              searchBy != "mobileNumber" ? "blue" : "gray"
            }-500 text-xs text-gray-300 px-2 py-[1px] flex items-center `}
          >
            USERS
          </div>
          <div
            onClick={
              searchBy === "mobileNumber"
                ? () => {
                    setSearchBy("adminUserName");
                  }
                : () => {
                    setSearchBy("mobileNumber");
                  }
            }
            className={`bg-${
              searchBy === "mobileNumber" ? "blue" : "gray"
            }-500 text-xs text-gray-300 px-2 py-[1px] flex items-center `}
          >
            MOBILE NUMBER
          </div>
        </div>
      </div>
      <div className="mt-1 w-[100%] flex justify-between">
        <button
          className="m-4"
          onClick={() => {
            setAdminUsers(
              adminUsers.map((user) => ({
                ...user,
                selected:
                  adminUsers.filter((user) => user.selected).length ===
                  adminUsers.length
                    ? false
                    : true,
              }))
            );
          }}
        >
          <span className="text-white bg-blue-500 rounded-[10px] px-2 py-1 text-sm">
            Select All
          </span>
        </button>
        <button onClick={() => handleAssignAdmin()}>
          <span className="text-white bg-blue-500 rounded-[10px] px-2 py-1 text-sm">
            Assign Admin
          </span>
        </button>
      </div>
      <div className="h-auto  w-[100%] ">
        <Table
          size={adminUsers.length}
          currentPage={currentPage}
          pageLimit={pageLimit}
          setPageLimit={setPageLimit}
          setCurrentPage={setCurrentPage}
          data={adminUsers}
          columns={columns}
          totalPages={1}
          showPagination={true}
          nextPage={nextPage}
          prevPage={prevPage}
        />
      </div>
    </>
  );
}
