import React, { createRef, useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  addNewPaymentDetailsAPI,
  updatePaymentDetailsAPI,
  uploadFileAPI,
} from "../../../service/UserService";
import TopNavigation from "../../../components/TopNavigation";
import { Switch } from "@material-tailwind/react";

export default function AddNewBank() {
  const location = useLocation();
  const [payload, setPayload] = useState({
    accountHolderName: null,
    accountNumber: null,
    bankType: null, // [BANK_SAVING,BANK_CURRENT,UPI_PAYTM,UPI_PHONEPE,UPI_GPAY,UPI_BHIM,]
    ifscCode: null,
    mobile: null,
    bankName: null,
    qrCodeImageUrl: null,
    upiId: null,
    type: null, //[upi,bank]
    isMethodActive: true,
  });
  const uploadFileRef = createRef();
  const [qrImage, setQrImage] = useState(null);
  const navigate = useNavigate();
  const [isImageChanged, setIsImageChanged] = useState(false);
  const handleAddBankDetails = async () => {
    if (qrImage) {
      const url = handleUploadFile();
      setPayload({
        ...payload,
        qrCodeImageUrl: url,
      });
    }

    const { data, statusCode, message } = await addNewPaymentDetailsAPI(
      payload
    );
    if (statusCode === 200) {
      toast("Record added successfully", {
        type: "success",
      });
      navigate("/bank-details");
    } else {
      toast(message, {
        type: "error",
      });
    }
  };
  const handleUploadFile = async () => {
    const file = qrImage[0];
    const { statusCode, data, response } = await uploadFileAPI(file);
    if (statusCode == 200) {
      const uri = data["File Url"].CREATED;
      toast("File uploaded successfully", { type: "success" });
      return uri;
    } else if (!statusCode) {
      const {
        data: { error },
      } = response.data;
      toast(error, { type: "error" });
      return false;
    }
  };
  const updatePaymentDetails = async () => {
    var filteredPayload = Object.keys(payload)
      .filter((key) => payload[key] !== null)
      .reduce((obj, key) => {
        obj[key] = payload[key];
        return obj;
      }, {});
    if (isImageChanged) {
      const url = await handleUploadFile();
      filteredPayload = {
        ...filteredPayload,
        qrCodeImageUrl: url,
      };
    } else {
      delete filteredPayload.qrCodeImageUrl;
    }
    const { data, statusCode, message } = await updatePaymentDetailsAPI(
      payload.paymentDetailsId,
      filteredPayload
    );
    if (statusCode === 200) {
      toast("Bank details updated successfully", {
        type: "success",
      });
      navigate("/bank-details");
    } else {
      toast(message, {
        type: "error",
      });
    }
  };
  const handleInputChange = (e) => {
    setPayload({ ...payload, [e.target.name]: e.target.value });
  };
  const checkAndLoadState = () => {
    if (location.state) {
      setPayload(location.state);
      setQrImage(location.state.qrCodeImageUrl);
    }
  };
  const handleSwitchChange = (e) => {
    setPayload({ ...payload, isMethodActive: e.target.checked });
  };
  useEffect(() => {
    checkAndLoadState();
  }, [location]);
  return (
    <>
      <TopNavigation
        isBackNav={true}
        showNotification={true}
        title={location.state ? "Update New Record" : "Add New Record"}
      />
      <div class="w-full max-w-lg mt-5 min-h-[91.5vh]">
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-first-name"
            >
              Account Holder Name
            </label>
            <input
              value={payload.accountHolderName}
              onChange={handleInputChange}
              class="appearance-none text-white block w-full bg-[#1D2023] border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none "
              id="grid-first-name"
              type="text"
              name="accountHolderName"
              placeholder="Account Holder Name"
            />
          </div>
          <div class="w-full md:w-1/2 px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-last-name"
            >
              Account Number
            </label>
            <input
              value={payload.accountNumber}
              onChange={handleInputChange}
              name="accountNumber"
              class="appearance-none block w-full bg-[#1D2023] text-white  border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none  focus:border-gray-500"
              id="grid-last-name"
              type="text"
              placeholder="Account Number"
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-6">
          <div class="w-full px-3">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-password"
            >
              IFSC Code
            </label>
            <input
              value={payload.ifscCode}
              onChange={handleInputChange}
              name="ifscCode"
              class="appearance-none block w-full bg-[#1D2023] text-white  border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none  focus:border-gray-500"
              id="grid-password"
              placeholder="IFSC Code"
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-3 mb-2">
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-city"
            >
              UPI Id
            </label>
            <input
              value={payload.upiId}
              onChange={handleInputChange}
              name="upiId"
              class="appearance-none block w-full bg-[#1D2023] text-white  border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none  focus:border-gray-500"
              id="grid-city"
              type="text"
              placeholder="UPI id"
            />
          </div>
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-state"
            >
              Bank Type
            </label>
            <div class="relative">
              <select
                value={payload.bankType}
                name="bankType"
                class="block appearance-none w-full bg-[#1D2023] border border-gray-200 text-white  py-3 px-4 pr-8 rounded leading-tight focus:outline-none  focus:border-gray-500"
                id="grid-state"
                onChange={handleInputChange}
              >
                <option value={"BANK_SAVING"}>Bank Savings</option>
                <option value={"BANK_CURRENT"}>Bank Current</option>
                <option value={"UPI_PAYTM"}>UPI Paytm</option>
                <option value={"UPI_PHONEPE"}>UPI PhonePe</option>
                <option value={"UPI_GPAY"}>UPI Gpay</option>
                <option value={"UPI_BHIM"}>UPI Bhim</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-state"
            >
              Payment Mode
            </label>
            <div class="relative">
              <select
                value={payload.type}
                onChange={handleInputChange}
                name="type"
                class="block appearance-none w-full bg-[#1D2023] border border-gray-200 text-white  py-3 px-4 pr-8 rounded leading-tight focus:outline-none  focus:border-gray-500"
                id="grid-state"
              >
                <option value={"BANK_SAVING"}>Payment Type</option>
                <option value={"upi"}>UPI</option>
                <option value={"bank"}>Bank</option>
              </select>
              <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                <svg
                  class="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
                </svg>
              </div>
            </div>
          </div>
          <div class="w-full md:w-1/3 px-3 mb-6 md:mb-0 mt-5">
            <label
              class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
              for="grid-state"
            >
              Is Active
            </label>
            <div class="relative">
              <Switch
                checked={payload.isMethodActive}
                onChange={handleSwitchChange}
                name="isActive"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </div>
          </div>
        </div>

        <div class="flex flex-wrap -mx-3 mb-2">
          <input
            onChange={(e) => setQrImage(e.target.files)}
            ref={uploadFileRef}
            className="hidden"
            type="file"
          />
          {qrImage ? (
            <img
              onClick={() => uploadFileRef.current.click()}
              className="max-h-[30vh] my-5 w-[100%]"
              src={location.state ? qrImage : URL.createObjectURL(qrImage[0])}
            />
          ) : (
            <div
              onClick={() => uploadFileRef.current.click()}
              className="cursor-pointer my-5  w-[100%] rounded-lg px-5 py-10 text-center border-4 border-dashed border-gray-500"
            >
              <p className="text-lg text-gray-500 font-bold">
                UPLOAD QR IMAGE *
              </p>
            </div>
          )}
        </div>
        <div className="w-[100%] flex justify-center align-middle">
          <button
            onClick={() =>
              location.state ? updatePaymentDetails() : handleAddBankDetails()
            }
            className="w-[30%] py-2 my-4 bg-blue-300 rounded-md self-center"
          >
            {location.state ? "Update New Record" : "Add New Record"}
          </button>
        </div>
      </div>
    </>
  );
}
