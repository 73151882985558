import { JSEncrypt } from "jsencrypt";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import TopNavigation from "../../../components/TopNavigation";
import {
  addAdminUserAPI,
  getRolesAPI,
  updateAdminUserAPI,
} from "../../../service/UserService";
import { PUBLIC_KEY } from "../../../utils/constants";

export default function AddNewAdmin() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;
  const [adminUser, setAdminUser] = useState({});
  const [roles, setRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const history = useNavigate();
  const encrypt = new JSEncrypt();
  encrypt.setPublicKey(PUBLIC_KEY);
  const getRoles = async () => {
    const { data } = await getRolesAPI();
    setRoles(data.role);
  };
  useEffect(() => {
    getRoles();
    if (state) {
      setAdminUser(state);
      setSelectedRoles(state.roles.map((role) => role.roleId));
    }
  }, []);

  const handleSelectRoles = (roleId) => {
    const index = selectedRoles.indexOf(roleId);
    if (index === -1) {
      setSelectedRoles([...selectedRoles, roleId]);
    } else {
      setSelectedRoles(
        selectedRoles.filter((selectedRoleId) => selectedRoleId !== roleId)
      );
    }
  };

  const handleSubmit = async () => {
    if (!adminUser.name || !adminUser.password) {
      if (!state) {
        toast.error("Please enter all the details");
        return;
      }
    }
    if (selectedRoles.length === 0) {
      toast.error("Please select atleast one role");
      return;
    }
    const payload = {
      ...adminUser,
      roleIds: selectedRoles,
      password: encrypt.encrypt(adminUser.password),
    };

    if (state) {
      if (state.name === adminUser.name) {
        delete payload.name;
      }
      if (state.password === adminUser.password) {
        delete payload.password;
      }
      if (state.roles.length === selectedRoles.length) {
        let flag = true;
        for (let i = 0; i < state.roles.length; i++) {
          if (state.roles[i].roleId !== selectedRoles[i]) {
            flag = false;
            break;
          }
        }
        if (flag) {
          delete payload.roles;
        }
      }
    }
    if (Object.keys(payload).length === 0) {
      toast.error("No changes made");
      return;
    }

    if (state) {
      const { data, statusCode, message } = await updateAdminUserAPI(
        payload.uid,
        payload
      );
      if (statusCode === 200) {
        toast.success(message);
        history("/home");
      } else {
        toast.error(message);
      }
    } else {
      const { data, statusCode, message } = await addAdminUserAPI(payload);
      if (statusCode === 200) {
        toast.success(message);
        history("/home");
      } else {
        toast.error(message);
      }
    }
  };
  const handleSelectAllRoles = (type) => {
    switch (type) {
      case "GENERAL":
        setSelectedRoles([
          ...selectedRoles,
          ...roles
            .filter((role) => role.roleType == "GENERAL")
            .map((role) => role.roleId),
        ]);
        break;
      case "DEPOSIT":
        setSelectedRoles([
          ...selectedRoles,
          ...roles
            .filter((role) => role.roleType == "DEPOSIT")
            .map((role) => role.roleId),
        ]);
        break;
      case "WITHDRAW":
        setSelectedRoles([
          ...selectedRoles,
          ...roles
            .filter((role) => role.roleType == "WITHDRAW")
            .map((role) => role.roleId),
        ]);
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <TopNavigation
        title="Admin Management"
        isBackNav={true}
        showNotification={true}
      />
      <div className="h-[100vh] flex flex-1 flex-col">
        <div className="flex flex-wrap mt-5">
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              Username
            </label>
            <input
              disabled={state}
              onChange={(e) =>
                setAdminUser({ ...adminUser, name: e.target.value })
              }
              value={adminUser.name}
              className="appearance-none block w-full bg-[#212528] text-gray-300 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none "
              id="grid-first-name"
              type="text"
              placeholder="Username"
            />
          </div>
          <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
            <label
              className="block uppercase tracking-wide text-gray-300 text-xs font-bold mb-2"
              htmlFor="grid-first-name"
            >
              Password
            </label>
            <input
              onChange={(e) =>
                setAdminUser({ ...adminUser, password: e.target.value })
              }
              value={adminUser.password}
              className="appearance-none block w-full bg-[#212528] text-gray-300 border  rounded py-3 px-4 mb-3 leading-tight focus:outline-none "
              id="grid-first-name"
              type="text"
              placeholder="Password"
            />
          </div>
        </div>

        <div className="flex items-center mt-2 flex-row justify-between">
          <label
            className="block uppercase mt-2 tracking-wide text-gray-300 text-xs font-bold mb-2"
            htmlFor="grid-first-name"
          >
            GENERAL
          </label>
          <button
            onClick={() => handleSelectAllRoles("GENERAL")}
            className="bg-blue-400 text-gray-300 font-bold px-2 py-1 rounded-md text-xs"
          >
            SELECT ALL
          </button>
        </div>
        <div className="flex flex-wrap my-2">
          {roles
            .filter(({ roleType }) => roleType == "GENERAL")
            .map(({ roleId, roleName, roleType, selected }, index) => (
              <div
                onClick={() => handleSelectRoles(roleId)}
                className={`${
                  selectedRoles.indexOf(roleId) != -1 ? "bg-[#10A2F8]" : ""
                } cursor-pointer px-2 text-gray-300 font-bold py-1 m-1 border-[1px] border-gray-500 rounded-md text-sm`}
              >
                {roleName.replaceAll("_", " ").replaceAll("ROLE", "ALLOW")}
              </div>
            ))}
        </div>
        <div className="flex items-center flex-row justify-between">
          <label
            className="block uppercase mt-2 tracking-wide text-gray-300 text-xs font-bold mb-2"
            htmlFor="grid-first-name"
          >
            DEPOSIT
          </label>
          <button
            onClick={() => handleSelectAllRoles("DEPOSIT")}
            className="bg-blue-400 text-gray-300 font-bold px-2 py-1 rounded-md text-xs"
          >
            SELECT ALL
          </button>
        </div>
        <div className="flex flex-wrap my-2">
          {roles
            .filter(({ roleType }) => roleType == "DEPOSIT")
            .map(({ roleId, roleName, roleType, selected }, index) => (
              <div
                onClick={() => handleSelectRoles(roleId)}
                className={`${
                  selectedRoles.indexOf(roleId) != -1 ? "bg-[#10A2F8]" : ""
                } cursor-pointer px-2 text-gray-300 font-bold py-1 m-1 border-[1px] border-gray-500 rounded-md text-sm`}
              >
                {roleName.replaceAll("_", " ").replaceAll("ROLE", "ALLOW")}
              </div>
            ))}
        </div>
        <div className="flex items-center flex-row justify-between">
          <label
            className="block uppercase mt-2 tracking-wide text-gray-300 text-xs font-bold mb-2"
            htmlFor="grid-first-name"
          >
            WITHDRAW
          </label>
          <button
            onClick={() => handleSelectAllRoles("WITHDRAW")}
            className="bg-blue-400 text-gray-300 font-bold px-2 py-1 rounded-md text-xs"
          >
            SELECT ALL
          </button>
        </div>
        <div className="flex flex-wrap my-2">
          {roles
            .filter(({ roleType }) => roleType == "WITHDRAW")
            .map(({ roleId, roleName, roleType, selected }, index) => (
              <div
                onClick={() => handleSelectRoles(roleId)}
                className={`${
                  selectedRoles.indexOf(roleId) != -1 ? "bg-[#10A2F8]" : ""
                } cursor-pointer px-2 text-gray-300 font-bold py-1 m-1 border-[1px] border-gray-500 rounded-md text-sm`}
              >
                {roleName.replaceAll("_", " ").replaceAll("ROLE", "ALLOW")}
              </div>
            ))}
        </div>
        <button
          onClick={handleSubmit}
          className="bg-[#10A2F8] mt-10 w-[50%] self-center text-white px-4 py-2 rounded-md"
        >
          {state ? "Update" : "Add"}
        </button>
      </div>
    </div>
  );
}
